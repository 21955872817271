@import './../../../../styles/index.scss';

.special {
  padding-top: 140px;
  padding-bottom: 147px;
  background-color: $c-yellow;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (max-width: 520px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .card {
    position: relative;
    width: 100%;
    max-width: 600px;
    height: auto;
    padding: 50px 40px;
    background-color: $c-bg;
    border-radius: 24px;

    @media screen and (max-width: 580px) {
      padding: 70px 25px;
    }

    .cardTitle {
      text-align: center;
      margin-bottom: 62px;

      & .blackFriday {
        display: block;
        margin-bottom: 10px;
      }

      & .offer {
        font-weight: $fw-regular;
      }
    }

    .priceContainers {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 150px;
      margin-bottom: 60px;

      @media screen and (max-width: 680px) {
        gap: 100px;
      }

      @media screen and (max-width: 600px) {
        gap: 0;
        justify-content: space-between;
      }
    }

    .actualPriceContainer {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .actualPrice {
      font-weight: $fw-bold;
      font-size: 36px;
      margin-bottom: 10px;

      @media screen and (max-width: 520px) {
        font-size: 30px;
      }
    }

    .discount {
      & span {
        font-weight: $fw-bold;
      }
    }

    .oldPrice {
      position: relative;
      font-weight: $fw-medium;
      font-size: 32px;

      @media screen and (max-width: 520px) {
        font-size: 28px;
      }
    }

    .line {
      position: absolute;
      height: 3px;
      width: 130px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $c-red;
    }

    .endingContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 50px;

      @media screen and (max-width: 520px) {
        flex-direction: column;
      }
    }

    .actionContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .actionText {
      font-size: 18px;
      margin-bottom: 10px;
    }

    .countdown {
    }

    .button {
      display: flex;
      gap: 10px;
      padding: 18px 26px;
      white-space: nowrap;
      font-family: $ff-secondary;
      font-size: $fs-navigation;
      width: fit-content;
      height: fit-content;
      max-height: 60px;
      border-radius: 40px;
      border: 1px solid $c-black;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $c-yellow;
      cursor: pointer;

      @media screen and (max-width: 680px) {
        white-space: wrap;
      }

      @media screen and (max-width: 415px) {
        padding: 14px 17px;
        max-height: 50px;
      }

      & span {
        font-family: $ff-secondary;
        font-weight: $fw-bold;
        font-size: 20px;
      }

      &:hover {
        outline: 1px solid $c-black;
      }

      &:active {
        outline: 1px solid $c-black;
        background-color: $c-btn-active;
      }
    }
  }

  .iconContainer {
    position: absolute;
    width: 49px;
    height: 36px;
    top: 30px;
    right: 30px;
  }
  .secondIconContainer {
    position: absolute;
    width: 23px;
    height: 39px;
    top: 0;
    right: -40px;
  }
  .thirdIconContainer {
    width: 45px;
    height: 40px;
  }
}
