.waveUp {
  background-image: url('./../../assets/img/wave-up.png');
  background-repeat: no-repeat;
  padding-bottom: 100px;


  @media screen and (max-width: 375px) {
    background-image: url('./../../assets/img/wave-up-mobile.png');
    background-position: center;
  }
}

.waveDown {
  background-image: url('./../../assets/img/wave-down.png');
  background-repeat: no-repeat;
  padding-bottom: 100px;
}
