@import './../../styles/variables';

.socialMedia .icon-facebook {
  fill: $c-yellow;
  height: 16px;
}

.socialMedia .icon-instagram {
  fill: $c-yellow;
  height: 16px;
}

.socialMedia .icon-telegram {
  fill: $c-yellow;
  height: 16px;
}

.icon-telegram {
  height: 15px;
  padding-right: 2px;
}
