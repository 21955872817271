@import './../../../styles/variables';


.container {
  width: 100%;
  max-width: 700px;
  height: 550px;
  // overflow: hidden;
  background-color: $c-bg;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 55px 50px;
  background-image: url('./../../../assets/img/wave-down.png'),
    url('./../../../assets/img/wave-up.png');
  background-repeat: no-repeat;
  background-position: top left, bottom left;

  @media screen and (min-width: 901px) and (max-width: 1095px) {
    max-width: 600px;
  }

  @media screen and (min-width: 700px) and (max-width: 900px) {
    max-width: 550px;
  }

  @media screen and (min-width: 500px) and (max-width: 699px) {
    max-width: 450px;
    height: 450px;
    padding: 55px 50px;
  }

  @media screen and (min-width: 375px) and (max-width: 499px) {
    max-width: 350px;
    height: 450px;
    padding: 55px 25px;
  }
}

.containerNone {
  display: none;
}

.heading {
  width: 100%;
  max-width: 400px;
  text-align: center;
  line-height: 32px;
  margin-bottom: 30px;

  @media screen and (min-width: 375px) and (max-width: 699px) {
    font-size: $fs-little-text;
    margin-bottom: 10px;
    line-height: 26px;
  }
}

.scroll {
  overflow: auto;
  max-height: 400px;
  padding: 20px;

  @media screen and (min-width: 375px) and (max-width: 699px) {
    padding: 30px;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: $c-yellow;
}

.form {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (min-width: 375px) and (max-width: 699px) {
    gap: 10px;
  }
}

.formPart {
  width: 100%;
  max-width: 530px;
  display: flex;
  flex-direction: column;
}

.radioBtnsParts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.radioBtnsFormsParts {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.radio {
  margin-right: 8px;
  vertical-align: middle;
}

.checkbox {
  vertical-align: middle;
  margin-right: 8px;
}

.contactMeParts {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.label {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  @media screen and (min-width: 375px) and (max-width: 699px) {
    font-size: $fs-little-text;
  }
}

.title {
  font-weight: $fw-medium;
  margin-bottom: 15px;

  @media screen and (min-width: 375px) and (max-width: 699px) {
    font-size: $fs-little-text;
  }
}

.input {
  padding: 13px;
  border: none;
  outline: 1px solid $c-gray;
  border-radius: 5px;
  margin-bottom: 5px;

  @media screen and (min-width: 500px) and (max-width: 699px) {
    padding: 10px;
  }

    @media screen and (min-width: 375px) and (max-width: 699px) {
    padding: 8px;
  }
}

.input::placeholder {
  color: rgb(173, 172, 172);
}

.input:focus {
  background-color: $c-yellow;
  outline-color: $c-btn-active;
}

.error {
  color: $c-red;
  height: 10px;

    @media screen and (min-width: 375px) and (max-width: 699px) {
    font-size: $fs-little-text;
  }
}

.btn {
  padding: 10px 15px;
  width: fit-content;
  background-color: $c-bg;
  border: 1px solid $c-black;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
}

.btn:hover {
  background-color: $c-btn-active;
  outline: 1px solid $c-black;
  border-radius: 5px;
}

.close {
  display: none;
}

.complete {
  font-size: $fs-course-title;
  font-weight: $fw-medium;
  text-align: center;
  line-height: 42px;
}

select {
  padding: 13px;
  border: none;
  outline: 1px solid $c-gray;
  border-radius: 5px;
  margin-bottom: 5px;


  @media screen and (min-width: 500px) and (max-width: 699px) {
    padding: 10px;
  }

    @media screen and (min-width: 375px) and (max-width: 699px) {
    padding: 8px;
  }
}

select::placeholder {
  color: rgb(173, 172, 172);
}

select:focus {
  outline-color: $c-btn-active;
}


