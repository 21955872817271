@import './../../styles/variables';

.menuButton {
  width: 32px;
  height: 32px;
  z-index: 100;
  position: absolute;
  color: $c-black;
  background-color: transparent;
  border: none;
  outline: none;
  top: 30px;
  right: 32px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    display: flex;
    top: 20px;
  }

  @media screen and (min-width: 500px) and (max-width: 995px) {
    display: flex;
    top: 21px;
    right: 23px;
    bottom: 21px;
  }
}

.socialMedia {
  display: none;
  justify-content: center;
  gap: 20px;
  width: 144px;
  height: 32px;
  z-index: 100;
  margin: 0 auto;

  @media screen and (max-width: 995px) {
    display: flex;
  }
}

.circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $c-yellow;
  color: $c-black;
}

.link {
  display: inline-block;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
