@import './../../styles/variables';

.wrapper {
  margin-bottom: 50px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 30px;
  border: 2px solid transparent;
  border-radius: 20px;
  width: 430px;
  height: 200px;
  box-shadow: 0px 4px 20px #57627333;
  margin-bottom: 20px;
  transition: $t-normal;

  @media screen and (max-width: 500px) {
    width: 330px;
    font-size: $fs-little-text;
  }

  &:hover {
    transform: scale(1.1, 1.1);
  }
}

.mainHeading {
  font-size: $fs-title;
  text-align: center;
  margin-bottom: 50px;

  @media screen and (max-width: 500px) {
    font-size: $fs-teachers;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.infos {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: $fw-medium;
}

.social {
  display: flex;
  flex-direction: column;
}