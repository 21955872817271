// ! Teenagers Slider
.teenagerSwiper {
  width: fit-content;
  height: 550px;
  padding-top: 10px;
}

.teenagerSwiper .swiper-wrapper {
  // transform: translate3d(0px, 0, 0);
  @media screen and (min-width: 700px) {
    justify-content: center;
  }
}

.teenagerSwiper .swiper-slide {
  max-width: 440px;

  @media screen and (max-width: 500px) {
    max-width: 350px;
    // padding: 16px;
    margin: 0 auto;

  }
}

.teenagerSwiper .swiper-slide:first-of-type {
  margin-left: 20px;

  @media screen and (max-width: 500px) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
  }
}

.teenagerSwiper .swiper-slide:last-of-type {
  margin-right: 20px;
}

.teenagerSwiper .swiper-button-prev {
  @media screen and (max-width: 500px) {
    left: 35%;
    top: 94%;
  }

  @media screen and (min-width: 500px) and (max-width: 600px) {
    left: 38%;
    top: 94%;
  }

  @media screen and (min-width: 600px) and (max-width: 750px) {
    left: 41%;
    top: 94%;
  }

  @media screen and (min-width: 750px) and (max-width: 900px) {
    left: 42%;
    top: 94%;
  }

  @media screen and (min-width: 900px) and (max-width: 1200px) {
    left: 44%;
    top: 94%;
  }
  @media screen and (min-width: 1200px) and (max-width: 1400px) {
    left: 45%;
    top: 94%;
  }
}

.teenagerSwiper .swiper-button-next {
  @media screen and (max-width: 500px) {
    right: 35%;
    top: 94%;
  }

  @media screen and (min-width: 500px) and (max-width: 600px) {
    right: 38%;
    top: 94%;
  }

  @media screen and (min-width: 600px) and (max-width: 750px) {
    right: 41%;
    top: 94%;
  }

  @media screen and (min-width: 750px) and (max-width: 900px) {
    right: 42%;
    top: 94%;
  }

  @media screen and (min-width: 900px) and (max-width: 1200px) {
    right: 44%;
    top: 94%;
  }
  @media screen and (min-width: 1200px) and (max-width: 1400px) {
    right: 45%;
    top: 94%;
  }
}

// ! Adults Slider
.coursesSwiper {
  height: 550px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;

  @media screen and (max-width: 500px) {
    padding-left: 10px;
  }
}

.coursesSwiper .swiper-container {
  width: 100%;
  height: 100%;
}

.coursesSwiper .swiper-slide {
  width: 100%;
  max-width: 440px;

  @media screen and (max-width: 500px) {
    max-width: 350px;
  }
}

.coursesSwiper .swiper-button-prev {
  @media screen and (max-width: 500px) {
    left: 35%;
    top: 94%;
  }

  @media screen and (min-width: 500px) and (max-width: 600px) {
    left: 38%;
    top: 94%;
  }

  @media screen and (min-width: 600px) and (max-width: 750px) {
    left: 41%;
    top: 94%;
  }

  @media screen and (min-width: 750px) and (max-width: 900px) {
    left: 42%;
    top: 94%;
  }

  @media screen and (min-width: 900px) and (max-width: 1200px) {
    left: 44%;
    top: 94%;
  }
  @media screen and (min-width: 1200px) and (max-width: 1400px) {
    left: 45%;
    top: 94%;
  }
}

.coursesSwiper .swiper-button-next {
  @media screen and (max-width: 500px) {
    right: 35%;
    top: 94%;
  }

  @media screen and (min-width: 500px) and (max-width: 600px) {
    right: 38%;
    top: 94%;
  }

  @media screen and (min-width: 600px) and (max-width: 750px) {
    right: 41%;
    top: 94%;
  }

  @media screen and (min-width: 750px) and (max-width: 900px) {
    right: 42%;
    top: 94%;
  }

  @media screen and (min-width: 900px) and (max-width: 1200px) {
    right: 44%;
    top: 94%;
  }
  @media screen and (min-width: 1200px) and (max-width: 1400px) {
    right: 45%;
    top: 94%;
  }
}
