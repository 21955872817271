@import './../../../../styles/variables';

.banner,
.bannerOn {
  position: relative;
  padding-top: 86px;
  padding-bottom: 172px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;

  @media screen and (max-width: $desktopBig) {
    padding-top: 0;
    padding-bottom: 60px;
    gap: 20px;
  }

  @media screen and (max-width: 500px) {
    padding-top: 60px;
  }

  .imageContainer {
    width: 100%;
    max-width: 670px;
    height: auto;

    @media screen and (max-width: $tablet) {
      display: none;
    }
  }

  .iconPencilContainer {
    width: 70px;
    height: 49px;
    position: absolute;
    top: 130px;
    left: 84px;

    @media screen and (max-width: $desktopBig) {
      top: 40px;
    }
  }

  .dotYellow {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: $c-yellow;
    bottom: 115px;
    left: 90px;
    border-radius: 50%;

    @media screen and (max-width: $desktop) {
      bottom: 50px;
      z-index: 1;
    }
  }

  .textBlock,
  .textBlockOn {
    position: relative;
    width: 100%;
    max-width: 553px;
    height: 553px;
    background-image: url('./../../../../assets/img/squares.png');
    background-size: 100%;
    padding: 60px;

    @media screen and (max-width: $desktop) {
      height: 420px;
      padding: 30px;
    }

    @media screen and (max-width: $desktop) {
      padding: 15px;
    }
  }

  .textBlockOn {
    padding-top: 90px;
  }

  .dotRed {
    width: 20px;
    height: 20px;
    background-color: $c-red;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    left: 14px;
  }

  .dotBlue {
    width: 30px;
    height: 30px;
    background-color: $c-blue;
    border-radius: 50%;
    position: absolute;
    top: 167px;
    right: 14px;

    // @media screen and (max-width: $desktop) {
    //   top: 120px;
    // }
  }

  .pageTitle {
    font-family: $ff-main;
    font-size: $fs-lesson-form;
    font-weight: $fw-bold;
    line-height: $line-height;
    text-shadow: 2px 2px #fff108;
    margin-bottom: 22px;

    @media screen and (max-width: $desktop) {
      font-size: 36px;
    }
  }

  .titleDescription {
    font-family: $ff-secondary;
    font-weight: $fw-bold;
    font-size: $fs-teachers;
    line-height: $line-height;
    margin-bottom: 48px;

    @media screen and (max-width: $desktop) {
      font-size: 22px;
      margin-bottom: 24px;
    }
  }

  .bannerText {
    font-family: $ff-main;
    font-size: $fs-main;
    line-height: $line-height;
    margin-bottom: 94px;

    @media screen and (max-width: $desktop) {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  .btnContainer {
    width: fit-content;
  }

  .button {
    white-space: nowrap;
    font-family: $ff-secondary;
    font-size: $fs-navigation;
    width: fit-content;
    height: fit-content;
    max-height: 60px;
    border-radius: 40px;
    border: 1px solid $c-black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $c-yellow;
    cursor: pointer;
  }

  .button:hover {
    outline: 1px solid $c-black;
  }

  .button:active {
    outline: 1px solid $c-black;
    background-color: $c-btn-active;

    @media screen and (max-width: 415px) {
      max-height: 50px;
    }
  }

  .link {
    display: block;
    padding: 18px 26px;

    @media screen and (max-width: 415px) {
      padding: 14px 17px;
    }
  }

  .button {
    display: block;
    padding: 18px 26px;
    white-space: nowrap;
    font-family: $ff-secondary;
    font-size: $fs-navigation;
    width: fit-content;
    height: fit-content;
    max-height: 60px;
    border-radius: 40px;
    border: 1px solid $c-black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $c-yellow;
    cursor: pointer;

    @media screen and (max-width: 415px) {
      padding: 14px 17px;
      max-height: 50px;
    }
  }

  .button:hover {
    outline: 1px solid $c-black;
  }

  .button:active {
    outline: 1px solid $c-black;
    background-color: $c-btn-active;
  }
}

.bannerOn {
  @media screen and (max-width: $desktop) {
    padding-top: 149px;
  }

  @media screen and (max-width: 500px) {
    padding-top: 135px;
  }
}
