.icon-pencil {
   @keyframes pencil {
    0% {
      transform: rotate(0deg);
    }
    3% {
      transform: rotate(15deg);
    }
    6% {
      transform: rotate(-15deg);
    }
    9% {
      transform: rotate(10deg);
    }
    12% {
      transform: rotate(-10deg);
    }
    14%,
    100% {
      transform: rotate(0);
    }
  }
}