@import './../../../../styles/index.scss';

.countdown {
  display: flex;
  align-items: center;
  gap: 10px;

  .cell {
    position: relative;
    width: 45px;
    height: 45px;
    background-color: $c-yellow;
    border: 1px solid $c-black;
    font-family: $ff-secondary;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cellDesc {
    position: absolute;
    bottom: -23px;
    left: 50%;
    transform: translateX(-50%);
    font-family: $ff-secondary;
    font-size: 18px;
  }
}
