@import './../../styles/variables';

.button {
  white-space: nowrap;
  font-family: $ff-secondary;
  font-size: $fs-navigation;
  width: fit-content;
  height: fit-content;
  max-height: 60px;
  border-radius: 40px;
  border: 1px solid $c-black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $c-yellow;
  cursor: pointer;
}

.button:hover {
  outline: 1px solid $c-black;
}

.button:active {
  outline: 1px solid $c-black;
  background-color: $c-btn-active;

  @media screen and (max-width: 415px) {
    max-height: 50px;
  }
}

.link {
  display: block;
  padding: 18px 26px;

  @media screen and (max-width: 415px) {
    padding: 14px 17px;
  }
}

.buttonTwo {
  display: block;
  padding: 18px 26px;
  white-space: nowrap;
  font-family: $ff-secondary;
  font-size: $fs-navigation;
  width: fit-content;
  height: fit-content;
  max-height: 60px;
  border-radius: 40px;
  border: 1px solid $c-black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $c-yellow;
  cursor: pointer;

  @media screen and (max-width: 415px) {
    padding: 14px 17px;
    max-height: 50px;
  }
}

.buttonTwo:hover {
  outline: 1px solid $c-black;
}

.buttonTwo:active {
  outline: 1px solid $c-black;
  background-color: $c-btn-active;
}
