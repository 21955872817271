@import './../../styles/variables';

.photosNumber {
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 27px;
  background-color: $c-black;
  border-radius: 50px;
  color: $c-yellow;
  position: absolute;
  top: 79%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media screen and (max-width: 550px) {
    top: 92.5%;
  }

  @media screen and (min-width: 551px) and (max-width: 700px) {
    top: 87%;
  }

  @media screen and (min-width: 701px) and (max-width: 1000px) {
    top: 88%;
  }

  @media screen and (min-width: 1001px) and (max-width: 1200px) {
    top: 89%;
  }

  @media screen and (min-width: 1201px) and (max-width: 1240px) {
    top: 92.5%;
  }
  @media screen and (min-width: 1241px) and (max-width: 1260px) {
    top: 91.5%;
  }
  @media screen and (min-width: 1261px) and (max-width: 1280px) {
    top: 90.5%;
  }
  @media screen and (min-width: 1281px) and (max-width: 1300px) {
    top: 89%;
  }
  @media screen and (min-width: 1301px) and (max-width: 1330px) {
    top: 87%;
  }
  @media screen and (min-width: 1331px) and (max-width: 1360px) {
    top: 85.5%;
  }
  @media screen and (min-width: 1361px) and (max-width: 1390px) {
    top: 83.5%;
  }
  @media screen and (min-width: 1391px) and (max-width: 1420px) {
    top: 81.5%;
  }
  @media screen and (min-width: 1421px) and (max-width: 1440px) {
    top: 80.5%;
  }
}

.wrapper {
  @media screen and (max-width: 550px) {
    .desktop {
      display: none;
    }
  }

  @media screen and (min-width: 551px) {
    .mobile {
      display: none;
    }
  }
}
