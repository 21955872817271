@import './../../../../styles/index.scss';

.teacher {
  padding-top: 76px;
  padding-bottom: 76px;

  background-color: $c-yellow;

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    margin-bottom: 66px;

    @media screen and (max-width: $tablet) {
      margin-bottom: 30px;
    }
  }

  .iconContainer {
    width: 30px;
    height: 30px;
  }

  .contentsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    @media screen and (max-width: $tablet) {
      flex-direction: column;
      position: relative;
      height: 1200px;
      justify-content: end;

      @media screen and (max-width: 600px) {
        height: 1250px;
      }

            @media screen and (max-width: 525px) {
        height: 1300px;
      }

      @media screen and (max-width: 450px) {
        height: 1250px;
      }

            @media screen and (max-width: 425px) {
        height: 1300px;
      }
    }

    .imgContainer {
      width: 100%;
      max-width: 480px;
      height: 692px;
      display: flex;
      justify-content: center;
      align-items: center;

      & img {
        object-fit: cover;

        @media screen and (max-width: $tablet) {
          transform: scale(110%);
        }
      }

      @media screen and (max-width: 1000px) {
        height: auto;
      }
      @media screen and (max-width: $tablet) {
        height: 600px;
        max-width: 600px;
        border-radius: 10%;
        overflow: hidden;
        position: absolute;
        top: 0;
      }

      @media screen and (max-width: 525px) {
        height: auto;
        max-width: 600px;
        border-radius: 10%;
        overflow: hidden;
        position: absolute;
        top: 0;
      }
    }

    .contentContainer {
      width: 100%;
      max-width: 795px;
      height: 692px;
      display: flex;
      align-items: center;

      @media screen and (max-width: $tablet) {
        height: auto;
        max-width: 600px;
        margin-bottom: 40px;
      }

      .cardTitle {
        font-family: $ff-main;
        font-size: 40px;
        font-weight: $fw-bold;
        line-height: $line-height;
        text-shadow: 2px 2px #fff108;
        margin-bottom: 20px;

        @media screen and (max-width: 1000px) {
          font-size: 32px;
        }
      }

      .list {
        background-image: url('./../../../../assets/img/squares.png');
        background-size: cover;
        padding: 30px;
        border-radius: 40px;
        line-height: 26px;

        @media screen and (max-width: 1000px) {
          padding: 20px;
        }

        @media screen and (max-width: $tablet) {
          padding: 150px 20px 20px 20px;
        }

        .listItem {
          display: flex;
          align-items: center;
          gap: 25px;
          margin-bottom: 30px;

          @media screen and (max-width: 1000px) {
            margin-bottom: 15px;
          }

          .itemText {
            font-size: 18px;

            @media screen and (max-width: $screen) {
              font-size: 16px;
            }

            @media screen and (max-width: 1000px) {
              font-size: 14px;
            }
          }
        }
      }
      .iconTwoContainer {
        width: 45px;
        height: 45px;
        flex-shrink: 0;

        @media screen and (max-width: 1000px) {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
