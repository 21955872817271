.myFormatSwiper {
  width: 100%;
  height: 900px;
  overflow: hidden;
  padding-right: 20px;

  @media screen and (max-width: 450px) {
    height: 750px;
  }
}

.myFormatSwiper .swiper-slide {
  width: 415px;


  @media screen and (max-width: 450px) {
    width: 330px;
  }
}

.myFormatSwiper .swiper-button-prev {
  left: 50%;
  transform: translateX(-70%);
}

.myFormatSwiper .swiper-button-next {
  left: 50%;
  transform: translateX(55%);
}
