.bot {
  animation: bot 2s infinite ease-out;
}

.icon-bot {
   @keyframes bot {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(15deg); 
    }
    50% {
      transform: rotate(-15deg); 
    }
    75% {
      transform: rotate(15deg); 
    }
    100% {
      transform: rotate(0deg); 
    }
  }
}
