@import './../../../../styles/index.scss';

.book {
  height: 600px;
  padding-top: 76px;
  padding-bottom: 76px;
  background-image: url('./../../../../assets/img/dtz/school-bg.png');
  background-attachment: fixed;
  background-size: cover;
  background-position: left bottom;
  display: flex;
  align-items: center;

  .contentContainer {
    position: relative;
    width: 100%;
    max-width: 620px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 625px) {
      max-width: 400px;
    }
  }

  .iconContainer {
    top: -20px;
    right: -25px;
    width: 47px;
    height: 62px;
    position: absolute;

    @media screen and (max-width: $tablet) {
      top: -70px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .secondIconContainer {
    top: 30px;
    right: -35px;
    width: 192px;
    position: absolute;

    @media screen and (max-width: $tablet) {
      top: 15px;
      transform: translateX(-50px);
      width: 150px;
    }

    @media screen and (max-width: 625px) {
      top: 40px;
      right: -10px;
      transform: rotate(-35deg);
      width: 140px;
    }

    @media screen and (max-width: 500px) {
      top: 25px;
      right: -10px;
      transform: rotate(-35deg);
      width: 140px;
    }
  }

  .titleContainer {
    margin-bottom: 37px;

    @media screen and (max-width: $tablet) {
      margin-bottom: 20px;
    }
  }

  .titleDesc {
    text-align: center;
    margin-bottom: 37px;
    @media screen and (max-width: $tablet) {
      margin-bottom: 20px;
    }

    @media screen and (max-width: 625px) {
      margin-bottom: 70px;
    }
  }

  .button {
    display: flex;
    gap: 10px;
    padding: 18px 26px;
    white-space: nowrap;
    font-family: $ff-secondary;
    font-size: $fs-navigation;
    width: fit-content;
    height: fit-content;
    max-height: 60px;
    border-radius: 40px;
    border: 1px solid $c-black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $c-yellow;
    cursor: pointer;

    @media screen and (max-width: 415px) {
      padding: 14px 17px;
      max-height: 50px;
    }

    & span {
      font-family: $ff-secondary;
      font-weight: $fw-bold;
      font-size: 20px;
    }

    &:hover {
      outline: 1px solid $c-black;
    }

    &:active {
      outline: 1px solid $c-black;
      background-color: $c-btn-active;
    }
  }
}
