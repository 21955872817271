@import './../../styles/variables';

.wrapper {
  padding-top: 77px;
  padding-bottom: 93px;
  width: 100%;
  background-color: $c-bg;
}

.heading {
  margin-bottom: 72px;
}

.accordion {
  width: 100%;
  max-width: 910px;
  margin: 0 auto;
}

.item {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  font-weight: $fw-medium;
  background-color: $c-yellow;
  position: relative;
  padding: 25px;
  border: 1px solid $c-black;
  height: auto;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: $t-normal;
  cursor: pointer;

  @media screen and (max-width: 375px) {
    height: auto;
    max-width: 320px;
  }
}

.smallHeading {
  line-height: $line-height;

  @media screen and (max-width: 375px) {
    max-width: 240px;
  }

  @media screen and (min-width: 375px) and (max-width: 435px) {
    max-width: 260px;
  }

  @media screen and (min-width: 435px) and (max-width: 485px) {
    max-width: 280px;
  }
}

.item::after {
  position: absolute;
  content: url('./../../assets/svg/arrow-down.svg');
  right: 25px;
  top: 25px;
  transition: $t-normal;
}

.open {
  height: auto;
  background-color: $c-bg;

  @media screen and (max-width: 375px) {
    height: auto;
  }
}

.open::after {
  transform: rotate(180deg);
}

.answer {
  font-size: $fs-little-text;
  line-height: $line-height;
  font-weight: $fw-regular;
  overflow: hidden;
  height: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  transition: opacity $t-normal;
}

.slideDown {
  height: 100%;
  opacity: 1;
  transition: opacity $t-normal;
}
