@import './../../styles/variables';

.wrapper {
  padding-top: 90px;
  margin: 0 auto;
  padding-bottom: 92px;

  @media screen and (max-width: 799px) {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 77px;
  }
}

.heading {
  margin-bottom: 68px;

  @media screen and (max-width: 700px) {
    margin-bottom: 38px;
  }
}

.cardsContainer {
  width: 100%;
  max-width: 1150px;
  display: flex;
  margin: 0 auto;
  gap: 20px;
  margin-bottom: 56px;

  @media screen and (max-width: 620px) {
    flex-direction: column;
    margin-bottom: 50px;
    gap: 25px;
  }

  @media screen and (min-width: 620px) and (max-width: 799px) {
    flex-direction: column;
    margin-bottom: 50px;
    gap: 25px;
    align-items: center;
  }
}

.card {
  position: relative;
  width: 100%;
  max-width: 563px;
  height: 103px;
  background-color: $c-yellow;
  border-radius: 20px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 500px) {
    padding: 10px 20px;
    height: 126px;
  }

  @media screen and (min-width: 500px) and (max-width: 700px) {
    font-size: $fs-little-text;
    padding: 10px 20px;
    height: 126px;
  }

  @media screen and (min-width: 800px) and (max-width: 1100px) {
    font-size: $fs-little-text;
    padding: 10px 20px;
    height: 126px;
  }
}

.iconContainer {
  position: absolute;
  top: 13px;
  right: 19px;
  width: 31px;
  height: 31px;
}

.laptopContainer {
  position: absolute;
  width: 172px;
  height: 115px;
  left: 13px;
  bottom: 8px;

  @media screen and (max-width: 499px) {
    display: none;
  }

  @media screen and (min-width: 800px) and (max-width: 899px) {
    display: none;
  }
}

.girlWithHeadphones {
  position: absolute;
  width: 117px;
  height: 139px;
  left: 21px;
  bottom: 0;

  @media screen and (max-width: 499px) {
    display: none;
  }

  @media screen and (min-width: 800px) and (max-width: 899px) {
    display: none;
  }
}

.reqContainer {
  margin: 0 auto;
  display: flex;
  width: 100%;
  max-width: 1150px;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 10px;

  @media screen and (max-width: 799px) {
    flex-direction: column;
    gap: 35px;
    margin-bottom: 0;
    max-width: 500px;
  }
}

.reqContainer .req:first-of-type {
  margin-bottom: 20px;
}

.one {
  padding-left: 213px;
  line-height: $line-height;
  font-weight: $fw-medium;

  @media screen and (max-width: 500px) {
    padding-left: 0;
  }

  @media screen and (min-width: 500px) and (max-width: 700px) {
    padding-left: 150px;
  }

  @media screen and (min-width: 800px) and (max-width: 900px) {
    padding-left: 0;
    margin: 0 auto;
  }

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    padding-left: 160px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1200px) {
    padding-left: 190px;
  }
}

.two {
  padding-left: 168px;
  font-weight: $fw-medium;
  line-height: $line-height;
  width: 100%;
  max-width: 500px;

  @media screen and (max-width: 500px) {
    padding-left: 0;
  }

  @media screen and (min-width: 500px) and (max-width: 700px) {
    padding-left: 120px;
  }

  @media screen and (min-width: 800px) and (max-width: 900px) {
    padding-left: 0;
    margin: 0 auto;
  }

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    padding-left: 115px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1200px) {
    padding-left: 135px;
  }
}

.req {
  width: 100%;
  max-width: 350px;
  height: fit-content;
  background-color: $c-bg;
  display: flex;
  gap: 15px;
  border-radius: 10px;
  align-items: center;

  @media screen and (min-width: 800px) and (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 200px;
  }

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 270px;
  }
}

.circleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  min-width: 64px;
  height: 64px;
  background-color: $c-yellow;
  border-radius: 50%;
}

.number {
  font-size: $fs-title;
  font-weight: $fw-bold;
}

.text {
  width: 100%;
  max-width: 270px;
  line-height: $line-height;
  font-weight: $fw-light;
}

.zoomCard {
  width: 100%;
  padding-left: 79px;
  display: flex;
  align-items: center;
  gap: 20px;

  @media screen and (min-width: 800px) and (max-width: 1100px) {
    padding-left: 0;
    justify-content: center;
  }
}

.linkContainer {
  position: relative;
  width: fit-content;
  height: fit-content;
  transition: $t-normal;
}

.linkContainer:hover {
  transform: scale(1.1, 1.1);
}

.arrow {
  position: absolute;
  right: -45px;
  top: 4px;
  width: 35px;
  height: 13px;
  filter: drop-shadow(2px 2px $c-yellow);
}

.shadow {
  position: absolute;
  top: 8px;
  width: 120px;
  height: 11px;
  background-color: $c-yellow;
}

.link {
  position: relative;
  line-height: $line-height;
  color: $c-blue;
  transition: $t-normal;
  font-weight: $fw-medium;
}
