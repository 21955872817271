@import './../../../../styles/variables';

.courseProgram {
  padding-top: 76px;
  padding-bottom: 147px;
  background-image: url('./../../../../assets/img/dtz/program-bg.png');
  background-repeat: no-repeat;
  background-size: 750px 850px;
  background-position-x: right;
  background-position-y: 163px;

  @media screen and (max-width: $desktopBig) {
    background-size: 630px 730px;
    background-position-x: 110%;
    padding-bottom: 76px;
  }

  @media screen and (max-width: $tablet) {
    background-position-x: 200%;
  }

  @media screen and (max-width: 700px) {
    background-position: bottom;
    background-size: 750px 850px;
    height: 1650px;
  }

  @media screen and (max-width: $phone) {
    background-size: 500px 600px;
  }

  .titleContainer {
    margin-bottom: 113px;
  }

  .accordionContainer {
    width: 100%;
    max-width: 700px;

    @media screen and (max-width: $desktop) {
      max-width: 430px;
    }

    @media screen and (max-width: $tablet) {
      max-width: 350px;
    }

    @media screen and (max-width: 700px) {
      max-width: 100%;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    font-weight: $fw-medium;
    background-color: $c-yellow;
    font-size: 18px;
    position: relative;
    padding: 22px 30px;
    box-shadow: 0px 4px 20px rgba(87, 98, 115, 0.2);
    height: auto;
    border-radius: 20px;
    margin-bottom: 15px;
    transition: $t-normal;
    cursor: pointer;

    @media screen and (max-width: 375px) {
      height: auto;
    }
  }

  .smallHeading {
    line-height: $line-height;

    @media screen and (max-width: 375px) {
      max-width: 240px;
    }

    @media screen and (min-width: 375px) and (max-width: 435px) {
      max-width: 260px;
    }

    @media screen and (min-width: 435px) and (max-width: 485px) {
      max-width: 280px;
    }
  }

  .item::after {
    position: absolute;
    content: url('./../../../../assets/svg/arrow-down.svg');
    right: 25px;
    top: 25px;
    transition: $t-normal;
  }

  .open {
    height: auto;
    background-color: $c-bg;

    @media screen and (max-width: 375px) {
      height: auto;
    }
  }

  .open::after {
    transform: rotate(180deg);
  }

  .answer {
    font-size: $fs-little-text;
    line-height: $line-height;
    font-weight: $fw-regular;
    overflow: hidden;
    height: 0;
    opacity: 0;
    display: flex;
    align-items: center;
    transition: opacity $t-normal;
  }

  .slideDown {
    height: 100%;
    opacity: 1;
    transition: opacity $t-normal;
  }
}
