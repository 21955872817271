.pencilContainer {
  position: absolute;
  left: -70px;
  top: 72px;
  width: 70px;
  height: 50px;
  animation: pencil 10s infinite ease-out;

  @media screen and (max-width: 500px) {
    top: -46px;
    left: 10px;
    width: 50px;
    height: 35px;
  }

  @media screen and (min-width: 500px) and (max-width: 565px) {
    top: 40px;
    left: 0;
    width: 50px;
    height: 35px;
  }

  @media screen and (min-width: 565px) and (max-width: 790px) {
    top: 60px;
    left: 0;
    width: 50px;
    height: 35px;
  }
  

  @media screen and (min-width: 875px) and (max-width: 995px) {
    top: 60px;
    left: 0;
    width: 50px;
    height: 35px;
  }

  @media screen and (min-width: 995px) and (max-width: 1370px) {
    left: -20px;
    top: 45px;
  }
}

.icon-pencil {
  @keyframes pencil {
    0% {
      transform: rotate(0deg);
    }
    3% {
      transform: rotate(15deg);
    }
    6% {
      transform: rotate(-15deg);
    }
    9% {
      transform: rotate(10deg);
    }
    12% {
      transform: rotate(-10deg);
    }
    14%,
    100% {
      transform: rotate(0);
    }
  }
}

.icon-ruler {
  z-index: 10;
  position: absolute;
  bottom: 100px;
  left: 50px;
  width: 102px;
  height: 65px;

  
  @media screen and (max-width: 1100px) {
    bottom: 75px;
    left: 0;
  }
}
