@import './../../styles/variables';

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  padding-top: 150px;
  padding-bottom: 180px;
  gap: 25px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    height: 900px;
  }
}

.imagesContainer {
  position: relative;
  width: 100%;
  max-width: 300px;

  @media screen and (max-width: 800px) {
    height: 430px;
    margin: 0 auto;
  }
}

.teamFrame {
  box-shadow: 0px 4px 20px #2c323a33;
  position: absolute;
  z-index: 1;
  top: -60%;
  right: 0;
  width: 204px;
  height: 151px;
  border-radius: 500px;
  background-image: url('./../../assets/img/team-frame.png');

  @media screen and (max-width: 800px) {
    width: 164px;
    height: 121px;
    background-size: cover;
  }
}

.schoolgirls {
  box-shadow: 0px 4px 20px #2c323a33;
  top: -60%;
  left: -85%;
  position: absolute;
  width: 121px;
  height: 164px;
  border-radius: 500px;
  background-image: url('./../../assets/img/schoolgirls.png');

  @media screen and (max-width: 600px) {
    display: none;
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
    top: -40%;
    left: -30%;
    z-index: 1;
  }

  @media screen and (min-width: 800px) and (max-width: 1140px) {
    display: none;
  }

  @media screen and (min-width: 1140px) and (max-width: 1340px) {
    top: -30%;
    left: -50%;
  }
}

.schoolgirl {
  box-shadow: 0px 4px 20px #2c323a33;
  bottom: -45%;
  left: -50%;
  position: absolute;
  // border: 1px solid black;
  width: 151px;
  height: 204px;
  border-radius: 500px;
  background-image: url('./../../assets/img/schoolgirl.png');

  @media screen and (max-width: 800px) {
    width: 121px;
    height: 164px;
    background-size: cover;
    bottom: -20%;
    left: -9%;
  }

    @media screen and (min-width: 600px) and (max-width: 800px) {
    bottom: -30%;
    left: -25%;
    z-index: 1;
  }

  @media screen and (min-width: 800px) and (max-width: 1140px) {
    bottom: -60%;
    left: 50%;
    z-index: 1;
  }
}

.otto {
  position: absolute;
  top: -35%;
  right: 63px;
  background-color: $c-yellow;
  // border: 1px solid black;
  width: 286px;
  height: 390px;
  border-radius: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    width: 232px;
    height: 316px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
    left: 50%;
    transform: translate(-50%, 0);
  }

  @media screen and (min-width: 800px) and (max-width: 820px) {
    top: -15%;
    width: 232px;
    height: 316px;
  }

  @media screen and (min-width: 820px) and (max-width: 930px) {
    top: -25%;
    width: 232px;
    height: 316px;
  }
}

.triangleContainer {
  width: 105px;
  height: 140px;
  margin-bottom: 31px;

  @media screen and (max-width: 600px) {
    width: 85px;
    height: 114px;
  }

  @media screen and (min-width: 800px) and (max-width: 930px) {
    width: 85px;
    height: 114px;
  }
}

.writingContainer {
  width: 160px;
  height: 33px;

  @media screen and (max-width: 600px) {
    width: 130px;
    height: 26px;

    @media screen and (min-width: 800px) and (max-width: 930px) {
      width: 130px;
      height: 26px;
    }
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  max-width: 460px;
  height: 100%;
  max-height: 300px;

  @media screen and (max-width: 800px) {
    max-width: 400px;
    margin: 0 auto;
    padding-top: 60px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.title {
  font-size: $fs-middle;
  line-height: 26px;
  font-weight: $fw-bold; //TODO change to 800 when the font Geometria downloaded
  text-shadow: 2px 2px $c-yellow;
}

.text {
  font-size: $fs-bigger-text;
  line-height: 26px;
}

.redDot {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $c-red;
  top: 0;
  transform: translateX(300px);

  @media screen and (max-width: 410px) {
    top: 20%;
    left: 5%;
  }

  @media screen and (min-width: 410px) and (max-width: 445px) {
    top: 20%;
    left: 10%;
  }

  @media screen and (min-width: 445px) and (max-width: 500px) {
    top: 20%;
    left: 15%;
  }

  @media screen and (min-width: 500px) and (max-width: 700px) {
    top: 20%;
    left: 30%;
  }

  @media screen and (min-width: 700px) and (max-width: 800px) {
    top: 20%;
    left: 40%;
  }
}

.blueDot {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $c-blue;
  right: 29px;
  transform: translateY(15px);
}

.ruler {
  position: absolute;
  right: 300px;
  bottom: 0px;

  @media screen and (max-width: 800px) {
    display: none;
  }
}
