@import './../../styles/variables';

.wrapper {
  background-color: $c-gray-bg;
  padding-top: 90px;
  padding-bottom: 95px;
}

.formsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;

  //   @media screen and (min-width: 710px) and (max-width: 1440px) {
  //   flex-wrap: nowrap;
  // }
}

.heading {
  margin-bottom: 56px;
}

.small {
  @media screen and (min-width: 1401px) and (max-width: 1450px) {
    max-width: 650px;
  }

    @media screen and (min-width: 1451px) {
    max-width: 680px;
  }
}

.big {
  max-width: 1000px;
}

.individual {
  position: absolute;
  right: 0;
  bottom: 0;

  @media screen and (min-width: 501px) and (max-width: 550px) {
    max-width: 180px;
  }

  @media screen and (min-width: 550px) and (max-width: 600px) {
    max-width: 210px;
  }

  @media screen and (min-width: 600px) and (max-width: 630px) {
    max-width: 250px;
  }

  @media screen and (min-width: 700px) and (max-width: 800px) {
    max-width: 240px;
  }
  @media screen and (min-width: 850px) and (max-width: 950px) {
    max-width: 220px;
  }

  @media screen and (min-width: 951px) and (max-width: 1050px) {
    max-width: 250px;
  }
}

.pair {
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;

  @media screen and (min-width: 501px) and (max-width: 550px) {
    max-width: 270px;
  }

  @media screen and (min-width: 550px) and (max-width: 600px) {
    max-width: 280px;
  }

  @media screen and (min-width: 600px) and (max-width: 630px) {
    max-width: 300px;
  }

  @media screen and (min-width: 630px) and (max-width: 700px) {
    max-width: 290px;
  }
  @media screen and (min-width: 850px) and (max-width: 950px) {
    width: 280px;
  }

  @media screen and (min-width: 951px) and (max-width: 1050px) {
    width: 310px;
  }
}

.group {
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;

  @media screen and (max-width: 500px) {
    width: 500px;
    right: -100px;
  }

  @media screen and (min-width: 501px) and (max-width: 550px) {
    right: -30px;
    width: 350px;
  }

  @media screen and (min-width: 550px) and (max-width: 600px) {
    width: 400px;
    right: -30px;
  }

  @media screen and (min-width: 600px) and (max-width: 700px) {
    width: 420px;
    right: -30px;
  }

  @media screen and (min-width: 701px) and (max-width: 850px) {
    width: 460px;
    right: -30px;
  }

  @media screen and (min-width: 850px) and (max-width: 949px) {
    width: 400px;
    right: -80px;
  }

  @media screen and (min-width: 950px) and (max-width: 1050px) {
    width: 400px;
    right: -80px;
  }

  @media screen and (min-width: 1050px) and (max-width: 1400px) {
    width: 550px;
    right: -70px;
  }

  @media screen and (min-width: 1401px) {
    width: 800px;
    bottom: -130px;
    right: -100px;
  }
}
