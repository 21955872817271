@import './../../styles/index.scss';

.wrapper {
  padding-top: 62px;
  margin-bottom: 50px;

  // @media screen and (min-width: 1001px) {
  //   display: none;
  // }
}

.tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 30px;

  @media screen and (max-width: 375px) {
    gap: 20px;
  }
}

.el {
  list-style-type: none;
  width: 100%;
}

.el {
  font-family: $ff-secondary;
  width: fit-content;
  font-weight: $fw-bold;
  font-size: $fs-navigation;
  padding: 12px 26px;
  background-color: $c-bg;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $c-black;
  border-radius: 40px;
  transition: $t-normal;
  cursor: pointer;

  @media screen and (max-width: 375px) {
    padding: 16px 10px;
  }
}

.el:hover {
  outline: 1px solid $c-black;
}

.clicked {
  background-color: $c-yellow;
  outline: 1px solid $c-black;
}

.table {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 100px;
}

.teenTable {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.adultTable {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mainHeading {
  font-size: $fs-title;
  text-align: center;
  margin-bottom: 50px;

    @media screen and (max-width: 500px) {
    font-size: $fs-teachers;
  }
}

.heading {
  font-size: $fs-course-title;
  text-align: center;
  margin-bottom: 30px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 30px;
  border: 2px solid transparent;
  border-radius: 20px;
  width: 430px;
  height: 300px;
  box-shadow: 0px 4px 20px #57627333;
  margin-bottom: 20px;
  transition: $t-normal;

  @media screen and (max-width: 500px) {
    width: 330px;
  }

  &:hover {
    transform: scale(1.1, 1.1);
  }
}

.textContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  font-weight: $fw-medium;

  @media screen and (max-width: 500px) {
    font-size: $fs-little-text;
  }
}

.leftContainer,
.rightContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 15px;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 15px;

    @media screen and (max-width: 500px) {
    font-size: $fs-little-text;
  }
}

.editBtn,
.deleteBtn {
  padding: 3px 8px;
  width: fit-content;
}

.newBtn {
  padding: 5px 10px;
}
