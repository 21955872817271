@import '../../styles/index.scss';

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  background-image: url('./../../assets/img/squares.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 450px;
  margin-bottom: 70px;

  @media screen and (max-width: 566px) {
    padding-top: 58px;
    margin-top: 46px;
    // padding-top: 121px;
    background-position: top;
    background-size: 490px 490px;
    margin-bottom: 0;
    // overflow: hidden;
  }

  @media screen and (max-width: 1100px) {
    align-items: center;
  }
}

.wrapperOn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-left: 30px;
  padding-right: 30px;
  background-image: url('./../../assets/img/squares.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 450px;
  margin-bottom: 70px;
  padding-top: 149px;
  background-position-y: 213px;

  @media screen and (max-width: 375px) {
    .blueDot {
      top: 392px;
    }
  }

  @media screen and (min-width: 376px) and (max-width: 385px) {
    .blueDot {
      top: 416px;
    }
  }

  @media screen and (min-width: 386px) and (max-width: 427px) {
    .blueDot {
      top: 398px;
    }
  }

  @media screen and (min-width: 428px) and (max-width: 469px) {
    .blueDot {
      top: 370px;
    }
  }

  @media screen and (min-width: 470px) and (max-width: 500px) {
    .blueDot {
      top: 352px;
    }
  }

  @media screen and (min-width: 501px) and (max-width: 549px) {
    .blueDot {
      top: 263px;
    }
  }

  @media screen and (min-width: 550px) and (max-width: 565px) {
    .blueDot {
      top: 199px;
    }
  }

  @media screen and (min-width: 566px) and (max-width: 590px) {
    .blueDot {
      top: 236px;
    }
  }

  @media screen and (min-width: 591px) and (max-width: 644px) {
    .blueDot {
      top: 294px;
    }
  }

  @media screen and (min-width: 645px) and (max-width: 715px) {
    .blueDot {
      top: 236px;
    }
  }

  @media screen and (min-width: 716px) and (max-width: 995px) {
    .blueDot {
      top: 294px;
    }
  }

  @media screen and (max-width: 400px) {
    .redDot {
      top: 124px;
    }
  }

  @media screen and (min-width: 401px) and (max-width: 500px) {
    .redDot {
      top: 52px;
    }
  }

  @media screen and (min-width: 567px) {
    .redDot {
      top: 126px;
    }
  }

  @media screen and (max-width: 500px) {
    margin-top: 46px;
    padding-top: 133px;
    padding-left: 15px;
    padding-right: 15px;
    background-position: top;
    background-size: 490px 490px;
    margin-bottom: 0;
    // overflow: hidden;
    background-position-y: 75px;
  }

  @media screen and (min-width: 501px) and (max-width: 566px) {
    padding-top: 58px;
    margin-top: 46px;
    padding-top: 161px;
    padding-left: 30px;
    padding-right: 30px;
    background-position: top;
    background-size: 490px 490px;
    margin-bottom: 0;
    // overflow: hidden;
    background-position-y: 103px;

    .redDot {
      top: 80px;
    }
  }
}

.redDot {
  position: absolute;
  background-color: $c-red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -23px;
  left: 52%;

  @media screen and (max-width: 400px) {
    top: 49px;
    left: 90%;
  }
}

.blueDot {
  position: absolute;
  right: 30px;
  top: 25%;
  background-color: $c-blue;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  @media screen and (max-width: 375px) {
    top: 65%;
    left: 78%;
  }

  @media screen and (min-width: 376px) and (max-width: 500px) {
    top: 60%;
    left: 78%;
  }

  @media screen and (min-width: 550px) and (max-width: 590px) {
    top: 15%;
    left: 78%;
  }

  @media screen and (min-width: 645px) and (max-width: 715px) {
    top: 15%;
    left: 78%;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  max-width: 590px;
  height: 580px;
  background-color: transparent;

  @media screen and (max-width: 500px) {
    // justify-content: start;
    padding-left: 13px;
    padding-right: 13px;
    height: 100%;
    padding-bottom: 34px;
  }
}

.title {
  margin-top: 0;
  width: 100%;
  font-size: $fs-main-title;
  font-weight: $fw-bold;
  line-height: 54px;
  margin-bottom: 25px;

  @media screen and (max-width: 500px) {
    font-size: $fs-title;
    margin-bottom: 14px;
    line-height: 40px;
  }

  @media screen and (min-width: 875px) and (max-width: 995px) {
    font-size: $fs-smaller-title;
    margin-bottom: 14px;
    line-height: 46px;
  }
}

.text {
  width: 100%;
  line-height: 26px;
  margin-bottom: 45px;

  @media screen and (max-width: 500px) {
    font-size: $fs-little-text;
  }
}

.image {
  position: relative;
  width: 100%;
  max-width: 564px;
  height: 580px;

  @media screen and (max-width: 1100px) {
    max-width: 400px;
    height: 416px;
  }

  @media screen and (max-width: 875px) {
    display: none;
  }
}

.btnContainer {
  width: fit-content;
}

.trialLessonContainer {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
}

.close {
  display: none;
}

.btnClose {
  position: fixed;
  top: 5%;
  right: 10%;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: $t-normal;

  @media screen and (min-width: 801px) and (max-width: 899px) {
    width: 45px;
    height: 45px;
    
  }

  @media screen and (min-width: 700px) and (max-width: 800px) {
    width: 45px;
    height: 45px;
    right: 3%;
    top: 6%;
  }

  @media screen and (min-width: 540px) and (max-width: 699px) {
    width: 45px;
    height: 45px;
    right: 7%;
    top: 4%;
  }

  @media screen and (min-width: 374px) and (max-width: 539px) {
    width: 45px;
    height: 45px;
    right: 5%;
    top: 3%;
  }
}

.btnClose:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.line {
  position: relative;
  background-color: rgb(174, 174, 174);
  width: 45px;
  height: 4px;
  border-radius: 5px;
  transform: rotate(45deg);

  @media screen and (min-width: 300px) and (max-width: 899px) {
    width: 35px;
  }
}

.line:before {
  position: absolute;
  content: '';
  top: 10px;
  width: 45px;
  height: 4px;
  background-color: rgb(174, 174, 174);
  border-radius: 5px;
  transform: translate(0px, -10px) rotate(90deg);

  @media screen and (min-width: 300px) and (max-width: 899px) {
    width: 35px;
  }
}
