@import './../../styles/variables';

.phone {
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  max-width: 40px;
  max-height: 40px;
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 99;
  padding: 10px;
  background-color: $c-yellow;
  opacity: 0.5;
  border: 1px solid transparent;

  &:hover {
    opacity: 1;
  }

  &:active {
    background-color: $c-btn-active;
    opacity: 0.5;
  }
  @media screen and (max-width: 500px) {
    right: 20px;
  }
}
