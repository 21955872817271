@import './../../../../styles/index.scss';

.format {
  padding-top: 76px;
  padding-bottom: 76px;
  margin-bottom: 76px;
  display: flex;
  flex-direction: column;

  .titleContainer {
    margin-bottom: 74px;
  }

  .contentContainer {
    padding-left: 40px;

    @media screen and (max-width: 1400px) {
      padding-left: 15px;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 415px;
    height: 800px;
    padding: 60px 20px 20px 20px;
    border: 1px solid $c-black;
    border-radius: 24px;
    cursor: pointer;
    transition: $t-normal;

    @media screen and (max-width: 450px) {
      width: 330px;
      padding: 30px 15px 15px 15px;
      height: 650px;
    }

    &:hover {
      background-color: $c-yellow;

      .divider {
        background-color: $c-black;
      }
    }
  }

  .iconContainer {
    position: absolute;
    width: 49px;
    height: 36px;
    top: 25px;
    right: 25px;

    @media screen and (max-width: 450px) {
      width: 39px;
      height: 26px;
    }
  }

  .cardTitleContainer {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 17px;
    padding-left: 20px;
    font-family: $ff-secondary;
    font-size: 32px;

    @media screen and (max-width: 450px) {
      font-size: 22px;
      margin-bottom: 13px;
    }
  }

  .secondIconContainer {
    width: 54px;
    height: 57px;

    @media screen and (max-width: 450px) {
      width: 39px;
      height: 42px;
    }
  }

  .divider {
    width: 100%;
    max-width: 353px;
    height: 1px;
    background-color: #d8d8d8;
    margin-bottom: 25px;
    transition: $t-normal;
  }

  .cardContentContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;

    @media screen and (max-width: 450px) {
      gap: 15px;
    }
  }

  .listItem,
  .listItemDisable {
    display: flex;
    align-items: center;
    gap: 15px;

    @media screen and (max-width: 450px) {
      font-size: 14px;
    }
  }

  .listItemDisable {
    opacity: 0.5;
  }

  .listIcon {
    width: 25px;
    height: 25px;
    background-color: $c-yellow;
    border-radius: 50px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .priceContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 36px;
  }

  .price {
    font-size: 36px;
    font-weight: $fw-bold;

    @media screen and (max-width: 450px) {
      font-size: 24px;
    }
  }

  .button {
    display: flex;
    gap: 10px;
    padding: 18px 26px;
    white-space: nowrap;
    font-family: $ff-secondary;
    font-size: $fs-navigation;
    width: fit-content;
    height: fit-content;
    max-height: 60px;
    border-radius: 40px;
    border: 1px solid $c-black;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $c-yellow;
    cursor: pointer;

    @media screen and (max-width: 415px) {
      padding: 14px 17px;
      max-height: 50px;
    }

    & span {
      font-family: $ff-secondary;
      font-weight: $fw-bold;
      font-size: 20px;
    }

    &:hover {
      outline: 1px solid $c-black;
    }

    &:active {
      outline: 1px solid $c-black;
      background-color: $c-btn-active;
    }
  }
}
