@import './../../styles/variables';

.container {
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 50px;
  padding: 20px;
  align-items: center;
  margin-top: 50px;

  @media screen and (min-width: 500px) and (max-width: 660px) {
    flex-direction: column;
  }

  @media screen and (min-width: 374px) and (max-width: 499px) {
    flex-direction: column;
    max-width: 280px;
    gap: 30px;
  }
}

.containerWithoutUser {
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loggedAs {
  width: 100%;
  max-width: 300px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background-color: $c-yellow;
  height: 50px;
  font-size: $fs-middle;
  border-radius: 10px;

  @media screen and (min-width: 374px) and (max-width: 499px) {
    font-size: $fs-main;
  }
}

.name {
  font-family: $ff-secondary;
  font-size: $fs-course-title;

  @media screen and (min-width: 374px) and (max-width: 499px) {
    font-size: $fs-teachers;
  }
}

.auth {
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 300px;
  background-color: $c-yellow;
  padding: 30px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authLogged {
  height: 100%;
  max-height: 100px;
  display: flex;
  background-color: $c-yellow;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 240px;
}

.fields {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.field {
  width: 100%;
  max-width: 400px;
  height: 50px;
  border: none;
  outline: 2px solid rgb(145, 142, 142);
  border-radius: 5px;
  padding: 5px 15px;
}

.buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.button {
  padding: 10px;
  width: fit-content;
  background-color: $c-bg;
  border: 1px solid $c-black;
  border-radius: 5px;
  cursor: pointer;

    @media screen and (min-width: 374px) and (max-width: 499px) {
    padding: 6px;
  }
}

button:disabled {
  background-color: $c-gray-bg;
  border: 2px solid $c-gray;
  cursor: default;
}

button:disabled:hover {
  background-color: $c-gray-bg;
  border: 2px solid $c-gray;
  cursor: default;
  outline: none;
}

.button:hover {
  background-color: $c-btn-active;
  outline: 1px solid $c-black;
  border-radius: 5px;
}

.text {
  font-size: $fs-bigger-text;

      @media screen and (min-width: 374px) and (max-width: 499px) {
    font-size: $fs-main;
  }
}

.line {
  border-bottom: 1px solid rgba(145, 142, 142, 0.7);
  margin-bottom: 50px;
}
