@import './../../styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 90px;
  background-image: url('./../../assets/img/hand-bg.png');
  background-attachment: fixed;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}

.heading {
  margin-bottom: 10px;

  @media screen and (max-width: 385px) {
    padding: 0 15px;
  }
}

.description {
  font-weight: $fw-light;
  margin-bottom: 65px;
  text-align: center;
}

.triangle {
  width: 47px;
  height: 62px;
  margin-bottom: 10px;
}

.btnContainer {
  position: relative;
}

.arrowLeft,
.arrowRight {
  width: 55px;
  height: 26px;
  position: absolute;
}

.arrowLeft {
  top: 0;
  right: 0;
  transform: translate(70px, -30px);
}

.arrowRight {
  top: 0;
  left: 0;
  transform: translate(-70px, -30px);
}
