@import '../../styles/index.scss';

.headerSocial .icon-facebook, .icon-instagram, .icon-telegram {
  height: 16px;
}

.icon-telegram {
  height: 15px;
  padding-right: 2px;
}

.icon-logo {
  width: 35px;
  height: 46px;
}


