@import './../../styles/variables';

.wrapper {
  display: flex;
  border-top: 2px solid $c-black;

  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
    border-color: transparent;
  }
}

.mapContainer {
  width: 100%;
  // max-width: 750px;
  height: 100%;
  max-height: 595px;

  @media screen and (max-width: 800px) {
    max-height: 400px;
  }

  @media screen and (min-width: 800px) and (max-width: 1000px) {
    max-height: 600px;
    max-width: 100%;
  }
}

.contactsContainer {
  border-left: 2px solid $c-black;
  padding-top: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: 27px;
  padding-right: 27px;
  max-width: 750px;

  @media screen and (max-width: 900px) {
    border-color: transparent;
    padding-top: 65px;
    padding-left: 27px;
    padding-right: 27px;
    height: 400px;
    max-width: 100%;
  }
  @media screen and (min-width: 900px) {
    min-width: 450px;
  }
}

.title {
  font-size: $fs-teachers;
  font-weight: $fw-medium;
  margin-bottom: 28px;
}

.contactTitle {
  font-size: $fs-main;
  font-weight: $fw-light;
  line-height: $line-height;
}

.contactInfo {
  font-size: $fs-main;
  font-weight: $fw-medium;
  line-height: $line-height;
}

.socialMedia {
  display: flex;
  gap: 16px;
}

.social {
  width: 32px;
  height: 32px;
  background-color: $c-black;
  border-radius: 50%;
  transition: $t-normal;
}

.social:hover {
  transform: scale(1.2, 1.2);
}

.link {
  color: $c-yellow;
  display: inline-block;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contacts {
  width: 100%;
  max-width: 500px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contactTitles {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contactInfos {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
