@import '../../styles/index.scss';

.wrapper {
  width: 100%;
  @media screen and (min-width: 500px) {
    padding-left: 30px;
    width: 100%;
  }
}

.wrapperOn {
  width: 100%;
  background-color: $c-bg;
  padding-left: 30px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  position: fixed;

  @media screen and (max-width: 500px) {
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    position: fixed;
    padding-left: 15px;
    width: 100%;
  }
}

.headerWrapper {
  background-color: $c-bg;
  width: 100%;
  height: 74px;
  border-bottom: 1px solid $c-line;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 75px;

  @media screen and (max-width: 500px) {
    margin-bottom: 0;
    width: 100%;
    height: 75px;
  }
}

.logo {
  @media screen and (max-width: 995px) {
    width: 30px;
    height: 40px;
  }
}

.logoWriting {
  width: 126px;
}

.logoContainer {
  display: flex;
  z-index: 101;
  gap: 15px;

  height: 46px;
}

.navbar {
  width: 100%;
  max-width: fit-content;
  height: fit-content;
}

.socialMedia {
  width: 100%;
  max-width: 128px;
  display: flex;
  justify-content: space-between;
  height: 32px;

  @media screen and (max-width: 995px) {
    display: none;
  }
}

.circle {
  width: 32px;
  height: 32px;
  background-color: $c-yellow;
  border-radius: 50%;
  transition: $t-normal;
}

.circle:hover {
  transform: scale(1.2, 1.2);
}

.link {
  display: inline-block;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.burgerLine {
  width: 27px;
  height: 5px;
  display: flex;
  align-items: center;
  transform: rotate(-2deg);
  transition: $t-normal;
}

.burgerOpen {
  width: 27px;
  height: 5px;
  display: flex;
  transition: $t-normal;
}

.burgerOpen:first-of-type {
  transform: rotate(45deg) translate(5px, 6px);
  transition: $t-normal;
}

.burgerOpen:nth-of-type(2) {
  transform: translateX(10px);
  opacity: 0;
  transition: $t-normal;
}

.burgerOpen:last-of-type {
  transform: rotate(-45deg) translate(5px, -6px);
  transition: $t-normal;
}

.buttons {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.button {
  white-space: nowrap;
  font-family: $ff-secondary;
  font-size: $fs-navigation;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  height: 60px;
  border-radius: 40px;
  border: 1px solid $c-black;
  display: flex;
  gap: 15px;
  justify-content: center;
  align-items: center;
  background-color: $c-yellow;
  cursor: pointer;
}

.button:hover {
  outline: 1px solid $c-black;
}

.button:active {
  outline: 1px solid $c-black;
  background-color: $c-btn-active;

  @media screen and (max-width: 415px) {
    max-height: 50px;
  }
}

.botContainer {
  width: 30px;
  height: 30px;
}
