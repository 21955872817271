
.aboutUsSwiper .swiper-button-prev {
  display: none;
}

.aboutUsSwiper .swiper-slide {
  width: 100%;
  max-width: 213px;
}

.aboutUsSwiper .swiper-button-next {
  display: none;
}

.aboutUsSwiper .swiper-slide:first-of-type {
  margin-left: 27px;
}

.aboutUsSwiper .swiper-slide:last-of-type {
  margin-right: 27px;
}