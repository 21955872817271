@font-face {
  font-family: 'Geometria';
  src: url('./../assets/fonts/geometria/Geometria-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Geometria';
  src: url('./../assets/fonts/geometria/Geometria.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Geometria';
  src: url('./../assets/fonts/geometria/Geometria-Medium.ttf')
    format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Geometria';
  src: url('./../assets/fonts/geometria/Geometria-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Geometria';
  src: url('./../assets/fonts/geometria/Geometria-ExtraBold.ttf')
    format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Caveat';
  src: url('./../assets/fonts/caveat/static/Caveat-Bold.ttf') format('truetype');
}
