@import './../../../styles/variables';

.map {
  width: 100%;
  // max-width: 750px;
  height: 593px;

  @media screen and (max-width: 800px) {
    max-height: 400px;
  }

  @media screen and (min-width: 800px) and (max-width: 1200px) {
    max-height: 600px;
    max-width: 100%;
    min-width: 450px;
  }
}

// ! Info Window

.infoWindow {
  position: absolute;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  text-align: center;
  top: 20%;
  right: 17%;
  width: 100%;
  max-width: 200px;
  height: 100px;
  background-color: $c-bg;
  border: 1px solid $c-black;
  border-radius: 20px 20px 20px 0;

  @media screen and (max-width: 500px) {
    top: 5%;
    right: 1%;
    max-width: 170px;
    height: 100px;
    font-size: $fs-mobile-map;
  }

  @media screen and (min-width: 430px) and (max-width: 470px) {
    top: 5%;
    right: 3%;
  }
  @media screen and (min-width: 470px) and (max-width: 550px) {
    top: 5%;
    right: 5%;
  }
  @media screen and (min-width: 550px) and (max-width: 600px) {
    top: 5%;
    right: 8%;
  }
  @media screen and (min-width: 600px) and (max-width: 650px) {
    top: 7%;
    right: 11%;
  }
  @media screen and (min-width: 650px) and (max-width: 700px) {
    top: 7%;
    right: 14%;
  }
  @media screen and (min-width: 700px) and (max-width: 800px) {
    top: 7%;
    right: 18%;
  }
  @media screen and (min-width: 800px) and (max-width: 880px) {
    top: 18%;
    right: 21%;
  }
  @media screen and (min-width: 880px) and (max-width: 899px) {
    top: 18%;
    right: 25%;
  }
    @media screen and (min-width: 900px) and (max-width: 1100px) {
    top: 18%;
    right: 4%;
  }
      @media screen and (min-width: 1100px) and (max-width: 1200px) {
    top: 18%;
    right: 10%;
  }
        @media screen and (min-width: 1200px) and (max-width: 1350px) {
    top: 18%;
    right: 13%;
  }
          @media screen and (min-width: 1350px) and (max-width: 1450px) {
    top: 19%;
    right: 16%;
  }
}

.title {
  font-size: $fs-little-text;

  @media screen and (max-width: 500px) {
    font-size: $fs-very-little;
  }
}

.city {
  margin-bottom: 5px;
}

.description {
  font-size: $fs-very-little;

  @media screen and (max-width: 500px) {
    font-size: $fs-mobile-map;
  }
}

.link {
  font-size: $fs-very-little;
  font-weight: $fw-bold;
  background-color: $c-yellow;

  @media screen and (max-width: 500px) {
    font-size: $fs-mobile-map;
  }
}
