@import '../../styles/index.scss';

.wrapper {
  background-color: $c-yellow;
  background-position: center;
  background-size: cover;
  margin-bottom: 75px;

  @media screen and (max-width: 375px) {
    padding: 0 12px;
  }
}

.cards {
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 10px;
  padding-bottom: 100px;

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    padding-bottom: 60px;
  }

  @media screen and (min-width: 500px) and (max-width: 1150px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    padding-bottom: 60px;
  }
}

.extraImgContainer {
  position: absolute;
  left: 34px;
  top: 18px;
  width: 66px;
  height: 35px;

  @media screen and (max-width: 1150px) {
    left: 10%;
    top: 18px;
  }

  @media screen and (max-width: 995px) {
    left: 9%;
    top: 18px;
  }

  @media screen and (max-width: 875px) {
    left: 8%;
    top: 18px;
  }

  @media screen and (max-width: 775px) {
    left: 7%;
    top: 18px;
  }

  @media screen and (max-width: 695px) {
    left: 5%;
    top: 18px;
  }

  @media screen and (max-width: 595px) {
    left: 2%;
    top: 18px;
  }

  @media screen and (max-width: 500px) {
    left: 20px;
    top: 18px;
  }
}

.heading {
  padding-top: 54px;
  margin-bottom: 70px;

  @media screen and (max-width: 375px) {
    margin-bottom: 55px;
  }
}

.card {
  width: 100%;
  max-width: 200px;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;

  @media screen and (max-width: 1150px) {
    margin: 0 auto;
  }
}

.imgContainer {
  width: 50px;
  transition: $t-normal;
}

.imgContainer:hover {
  transform: scale(1.2, 1.2);
}

.text {
  text-align: center;
  max-width: 170px;
  height: 100px;
  font-size: $fs-little-text;
  line-height: 26px;
}
