@import './../../styles/index.scss';

.wrapper {
  padding-top: 62px;
margin-bottom: 50px;

  @media screen and (max-width: 1000px) {
    // padding-left: 25px;
    // padding-right: 25px;
    // padding-bottom: 80px;
    display: none;
  }
}

.table {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 100px;
}

.teenTable {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.adultTable {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mainHeading {
  font-size: $fs-title;
  text-align: center;
  margin-bottom: 50px;
}

.heading {
  font-size: $fs-course-title;
  text-align: center;
  margin-bottom: 30px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 30px;
  border: 2px solid transparent;
  border-radius: 20px;
  width: 430px;
  height: 300px;
  box-shadow: 0px 4px 20px #57627333;
  margin-bottom: 20px;
  transition: $t-normal;

  @media screen and (max-width: 375px) {
    padding: 22px 30px;
    height: 180px;
  }

  &:hover {
    transform: scale(1.1, 1.1);
  }
}

.textContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  font-weight: $fw-medium;
}

.leftContainer,
.rightContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 15px;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.editBtn,
.deleteBtn {
  padding: 3px 8px;
  width: fit-content;
}

.newBtn {
  padding: 5px 10px;
}
