@import './../../styles/variables';

.wrapper {
  padding-top: 62px;
  padding-bottom: 120px;
  background-color: $c-gray-bg;

  @media screen and (max-width: 920px) {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 80px;
  }
}

.cardsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;

  // @media screen and (max-width: 920px) {
  //   display: none;
  // }
}



.mobileCardsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // @media screen and (min-width: 920px) {
  //   display: none;
  // }
}

.tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 30px;

  @media screen and (max-width: 400px) {
    gap: 20px;
  }
}

.el {
  list-style-type: none;
  width: 100%;
}

.el {
  font-family: $ff-secondary;
  width: fit-content;
  font-weight: $fw-bold;
  font-size: $fs-navigation;
  padding: 12px 26px;
  background-color: $c-bg;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $c-black;
  border-radius: 40px;
  transition: $t-normal;
  cursor: pointer;

  @media screen and (max-width: 375px) {
    padding: 12px 18px;
  }
}

.el:hover {
  outline: 1px solid $c-black;
}

.clicked {
  background-color: $c-yellow;
  outline: 1px solid $c-black;
}

.heading {
  margin-bottom: 49px;

  @media screen and (max-width: 375px) {
    margin-bottom: 36px;
  }
}

.container {
  width: 100%;
  max-width: 448px;
}

.containerHeading {
  font-size: $fs-teachers;
  font-weight: $fw-medium;
  text-align: center;
  margin-bottom: 27px;
}

.card {
  position: relative;
  padding: 10px 30px;
  border: 2px solid transparent;
  border-radius: 20px;
  width: 100%;
  max-width: 448px;
  height: fit-content;
  box-shadow: 0px 4px 20px #57627333;
  margin-bottom: 14px;
  transition: $t-normal;

  @media screen and (max-width: 375px) {
    padding: 22px 30px;
    height: 180px;
  }

    @media screen and (min-width: 375px) and (max-width: 430px) {
  padding: 10px 20px;
  }
}

.cardYellow {
  background-color: $c-yellow;
}

.cardRed {
  background-color: rgb(229, 83, 83);
  opacity: 0.6;
  pointer-events: none

}

.textContainer {
  width: 100%;
  max-width: 320px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: $t-normal;

  @media screen and (max-width: 375px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
  }
}

.title {
  font-size: $fs-teachers;
  font-weight: $fw-medium;
  margin-bottom: 5px;

  @media screen and (max-width: 500px) {
    font-size: $fs-bigger-text;
  }
}

.places {
  font-size: $fs-teachers;
  font-weight: $fw-medium;
  margin-bottom: 5px;
}

.maxPlaces {
  font-size: $fs-little-text;
}

.teacher,
.placesText,
.book {
  font-size: $fs-little-text;
}

.book {
  margin-bottom: 5px;
}

.iconCon {
  height: 20px;
  width: 20px;
  margin-bottom: 6px;
}

.titleCon {
  display: flex;
  align-items: center;
  gap: 10px;
} 

.iconContainer {
  position: absolute;
  right: 25px;
  top: 20px;
  height: 40px;
  width: 23px;
  transition: $t-normal;

  @media screen and (min-width: 376px) and (max-width: 470px) {
  width: 20px;
  top: 12px;
  }
}

.card:hover .iconContainer {
  opacity: 0;
}

.card:hover .textContainer {
  opacity: 0;
}

.button {
  position: absolute;
  left: 50%;
  opacity: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: $t-normal;
}

.card:hover {
  background-color: $c-yellow;
  border-color: $c-black;
}

.card:hover .button {
  opacity: 1;
  background-color: $c-yellow;
}

.card .button:active {
  transition: none;
  background-color: $c-btn-active;
}

.btnCourse {
  padding: 10px;
}

.none {
  display: none;
}
