@import './../../../../styles/index.scss';

.video {
  position: relative;
  background-color: $c-gray-bg;
  padding-bottom: 76px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .titleContainer {
    margin: 70px;
  }

  .redDot {
    position: absolute;
    top: 38px;
    left: 158px;
    width: 20px;
    height: 20px;
    background-color: $c-red;
    border-radius: 50px;

    @media screen and (max-width: $tablet) {
      top: 80px;
      left: 40px;
    }
  }

  .blueDot {
    position: absolute;
    top: 69px;
    left: 65%;
    width: 30px;
    height: 30px;
    background-color: $c-blue;
    border-radius: 50px;

    @media screen and (max-width: $desktopBig) {
      top: 30px;
    }

    @media screen and (max-width: $tablet) {
      width: 20px;
      height: 20px;
    }
  }

  .yellowDot {
    position: absolute;
    bottom: 324px;
    right: 165px;
    width: 30px;
    height: 30px;
    background-color: $c-yellow;
    border-radius: 50px;

    @media screen and (max-width: $screen) {
      right: 60px;
    }

    @media screen and (max-width: $desktopBig) {
      right: 20px;
      width: 20px;
      height: 20px;
    }

    @media screen and (min-width: $tablet) and (max-width: 850px) {
      display: none;
    }

    @media screen and (max-width: $tablet) {
      bottom: 200px;
      right: 10px;
    }

    @media screen and (max-width: $phone) {
      display: none;
    }
  }

  .iconSmile {
    position: absolute;
    top: 384px;
    left: 64px;
    width: 45px;
    height: 45px;

    @media screen and (max-width: $desktopBig) {
      width: 35px;
      height: 35px;
      left: 15px;
    }

    @media screen and (min-width: $tablet) and (max-width: 810px) {
      display: none;
    }

    @media screen and (max-width: $tablet) {
      top: 300px;
      left: 10px;
    }

    @media screen and (max-width: $phone) {
      display: none;
    }
  }

  .iconSmileTwo {
    position: absolute;
    bottom: 38px;
    left: 178px;
    width: 45px;
    height: 45px;

    @media screen and (max-width: $screen) {
      width: 40px;
      height: 41px;
      bottom: 15px;
      left: 60px;
    }
  }

  .iconLightning {
    position: absolute;
    top: 158px;
    right: 113px;
    width: 23px;
    height: 40px;

    @media screen and (max-width: 1250px) {
      right: 20px;
    }

    @media screen and (min-width: $tablet) and (max-width: 845px) {
      top: 100px;
    }

    @media screen and (max-width: 630px) {
      top: 120px;
    }
  }

  .iconHeart {
    position: absolute;
    bottom: 23px;
    right: 169px;
    width: 52px;
    height: 54px;

    @media screen and (max-width: $screen) {
      width: 40px;
      height: 41px;
      bottom: 15px;
    }

    @media screen and (max-width: 600px) {
      right: 40px;
    }
  }

  .videoContainer {
    flex-shrink: 0;
    box-shadow: 15px 15px $c-yellow;
    width: 894px;
    height: 524px;

    @media screen and (max-width: $desktop) {
      width: 694px;
      height: 424px;
    }

    @media screen and (max-width: $tablet) {
      width: 494px;
      height: 300px;
    }

    @media screen and (max-width: 600px) {
      width: 374px;
      height: 210px;
    }

    @media screen and (max-width: $phone) {
      width: 334px;
      height: 190px;
    }
  }
}
