@import '../../styles/variables';

.wrapper {
  background-color: $c-yellow;
  padding: 100px 0;
}

.cardContainer {
  width: 100%;
  max-width: 213px;
  height: 263px;
  background-color: $c-bg;
  border: 1px solid $c-black;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: $t-normal;
  padding: 14px; //TODO It must be 20px, when te font is Geometry
}

.cardContainer:hover {
  outline: 1px solid $c-black; // outline doesn't change the size of the content, border does
}

.iconContainer {
  width: 50px;
  height: 47px;
  margin-bottom: 37px;
  margin-top: 20px;
  transition: $t-normal;
}

.iconContainer:hover {
  transform: scale(1.2, 1.2);
}

.heading {
  margin-bottom: 17px;
  font-size: $fs-bigger-text;
  font-weight: $fw-bold;
}

.text {
  font-size: 13px; //TODO Geometria - change fs to 14px
  line-height: 26px;
  font-weight: $fw-semi-bold;
}
