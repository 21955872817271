// ? Fonts
// Font Families
$ff-main: 'Geometria', sans-serif;
$ff-secondary: 'Caveat', cursive;

// Font sizes
$fs-mobile-map: 10px;
$fs-very-little: 12px;
$fs-little-text: 14px;
$fs-main: 16px;
$fs-bigger-text: 18px;
$fs-navigation: 20px;
$fs-middle: 22px;
$fs-teachers: 24px;
$fs-course-title: 28px;
$fs-title: 32px;
$fs-smaller-title: 38px;
$fs-main-title: 44px;
$fs-lesson-form: 48px;

// Font weights
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;

// ? Line height
$line-height: 26px;
$line-height-small: 18px;

// ? Colors
$c-bg: #fff;
$c-black: #000000;
$c-yellow: #fff108;
$c-red: #fa0101;
$c-blue: #0e356c;
$c-gray: #a5a5a5;
$c-card-shadow: #57627333;
$c-gray-bg: #F7F8F9;
$c-line: #efefef;
$c-btn-active: #E5C100;



// ? Transition
$t-normal: 0.35s cubic-bezier(0.4, 0, 0.2, 1);

// ? Media Queries: Breakpoints
$small: 320px;
$phone: 480px;
$tablet: 768px;
$desktop: 1024px;
$desktopBig: 1200px;
$screen: 1400px;
$large: 1920px;
