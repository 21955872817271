@import './../../../styles/variables';

.card {
  position: relative;
  width: 440px;
  height: 460px;
  padding: 30px 30px 20px 30px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  border: 1px solid transparent;
  border-radius: 20px;
  background-image: url('./../../../assets/img/squares.png');
  line-height: 26px;
  transition: $t-normal;
  box-shadow: 0px 4px 20px #57627333;

  @media screen and (max-width: 500px) {
    max-width: 350px;
    padding: 16px;
  }
}

.card:hover {
  background-image: none;
  background-color: $c-yellow;
  border-color: $c-black;
  transition: $t-normal;
}

.pencil {
  position: absolute;
  width: 56px;
  height: 40px;
  top: 16px;
  right: 21px;

  @media screen and (max-width: 500px) {
    width: 46px;
    height: 34px;
  }
}

.pencilShadow {
  position: absolute;
  width: 60px;
  height: 46px;
  top: 13px;
  right: 19px;

  @media screen and (max-width: 500px) {
    width: 50px;
    height: 38px;
    top: 14px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  transition: $t-normal;
}

.title {
  width: 100%;
  max-width: 350px;
  font-weight: $fw-bold;
  margin-bottom: 25px;
  text-wrap: wrap;

  @media screen and (max-width: 500px) {
    max-width: 260px;
  }
}

.duration {
  font-family: $ff-secondary;
  font-size: $fs-navigation;

  @media screen and (max-width: 500px) {
    font-size: $fs-bigger-text;
  }
}

.books {
  font-weight: $fw-medium;
  font-size: $fs-main;
}

.book {
  font-size: $fs-little-text;
  font-weight: $fw-medium;
  display: flex;
  align-items: center;
  gap: 5px;

  @media screen and (max-width: 500px) {
    font-size: $fs-very-little;
  }
}

.description {
  font-weight: $fw-medium;
  font-size: $fs-little-text;
  margin-bottom: 10px;

  @media screen and (max-width: 500px) {
    font-size: $fs-very-little;
  }
}

.text {
  @media screen and (max-width: 500px) {
    font-size: $fs-little-text;
  }
}

.price {
  font-size: $fs-teachers;
  font-weight: $fw-semi-bold;

  @media screen and (max-width: 500px) {
    font-size: $fs-middle;
  }
}

.currency {
  font-size: $fs-little-text;
  font-weight: $fw-medium;
}

.button {
  margin-top: 13px;
  width: 100%;
  max-width: 350px;
  padding: 18px;
  border: 1px solid $c-black;
  border-radius: 40px;
  cursor: pointer;
  background-color: transparent;
  font-family: $ff-secondary;
  font-size: $fs-navigation;
  font-weight: $fw-bold;
  transition: $t-normal;
}

.groups {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 50px;

  @media screen and (max-width: 500px) {
    gap: 15px;
  }
}

.smallText {
  font-size: 13px;
}

.priceContainer {
  display: flex;
  flex-direction: column;
}

.iconCon {
  height: 20px;
  width: 20px;
}
