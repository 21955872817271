@import './../../styles/variables';

.heading {
  font-size: $fs-title;
  font-weight: $fw-bold;
  text-align: center;

  @media screen and (max-width: 500px) {
    font-size: $fs-teachers;
    font-weight: $fw-medium;
  }
}