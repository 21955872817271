@import './../../../../styles/index.scss';

.reviews {
  height: 730px;
  padding-top: 76px;
  position: relative;
  background-color: $c-yellow;
  padding-bottom: 76px;

  @media screen and (max-width: $desktop) {
    height: 1300px;
  }

  @media screen and (max-width: 860px) {
    height: 1500px;
  }

  .iconSmile {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 120px;
    right: 348px;

    @media screen and (max-width: $desktop) {
      right: 150px;
      top: 170px;
    }

        @media screen and (max-width: 700px) {
      right: 50px;
      top: 130px;
    }
  }

  .iconPencil {
    position: absolute;
    width: 56px;
    height: 40px;
    right: 71px;
    bottom: 182px;

    @media screen and (max-width: 1320px) {
      bottom: 210px;
    }

    @media screen and (max-width: $desktop) {
      bottom: 150px;
    }

    @media screen and (max-width: 620px) {
      bottom: 50px;
    }

    @media screen and (max-width: 450px) {
      bottom: 30px;
      right: 30px;
    }
  }

  .iconRuler {
    position: absolute;
    width: 74px;
    height: 48px;
    bottom: 10px;
    left: 604px;

    @media screen and (max-width: 1400px) {
      bottom: -90px;
      left: 500px;
    }

    @media screen and (max-width: 1100px) {
      bottom: -70px;
      left: 400px;
    }

    @media screen and (max-width: $desktop) {
      bottom: 300px;
      left: 50px;
    }

    @media screen and (max-width: 670px) {
      bottom: 230px;
      left: 10px;
    }

    @media screen and (max-width: 670px) {
      bottom: 190px;
      left: 10px;
    }
  }
  .comment {
    position: absolute;
    width: 100%;
    max-width: 450px;
    border-radius: 20px;
    border: 1px solid $c-black;

    @media screen and (max-width: 515px) {
      max-width: 340px;
    }

    & img {
      border-radius: 20px;
    }
  }

  .comment:hover {
    z-index: 20;
  }

  .commentOne {
    left: 68px;
    bottom: 69px;
    transform: rotate(-10deg);

    @media screen and (max-width: 1300px) {
      left: 20px;
    }

    @media screen and (max-width: 400px) {
      left: 10px;
    }
  }

  & .commentTwo {
    left: 103px;
    top: 173px;
    transform: rotate(6deg);

    @media screen and (max-width: 1300px) {
      left: 30px;
    }

    @media screen and (max-width: 400px) {
      left: 10px;
    }
  }

  .commentThree {
    right: 83px;
    top: 166px;

    @media screen and (max-width: 1300px) {
      right: 20px;
    }

    @media screen and (max-width: $desktop) {
      right: 20px;
      top: 300px;
    }

    @media screen and (max-width: 860px) {
      right: 20px;
      top: 350px;
    }
  }

  .commentFour {
    left: 50%;
    top: 50%;
    transform: rotate(8deg) translate(-70%, -50%);
    max-width: 300px;

    @media screen and (max-width: $desktop) {
      left: 320px;
    }

    @media screen and (max-width: 860px) {
      transform: rotate(8deg) translate(-70%, -30%);
    }

    @media screen and (max-width: 470px) {
      transform: rotate(8deg) translate(-100%, -30%);
    }
  }

  .commentFive {
    right: 130px;
    bottom: 67px;
    transform: rotate(8deg);

    @media screen and (max-width: 1300px) {
      right: 100px;
    }

    @media screen and (max-width: $desktop) {
      right: 100px;
      bottom: 350px;
    }

    @media screen and (max-width: 860px) {
      right: 30px;
      bottom: 350px;
    }

    @media screen and (max-width: 400px) {
      right: 10px;
      bottom: 350px;
    }
  }
}
