@import '../../styles/variables';

.navbar {
  width: 100%;
}

.nav-links {
  display: flex;
  gap: 47px;
  align-items: center;
  width: 75%;
}

.link {
  line-height: 20px;
  font-family: $ff-secondary;
  font-size: $fs-navigation;
  white-space: nowrap;
  list-style-type: none;
  text-decoration: none;
  cursor: pointer;
  color: $c-black;
  text-align: center;
  width: 100%;
  height: 50px;
  transition: transform 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-menu-icon {
  display: none;
}

.link:hover {
  transform: scale(1.15, 1.15);
}

.link:active {
  color: $c-btn-active;
}

.link:hover:before {
  opacity: 1;
}

.link:hover::after {
  opacity: 1;
}

@media screen and (max-width: 995px) {
  .nav-links {
    display: none;
  }

  .nav-links-mobile {
    position: absolute;
    display: block;
    background-color: $c-bg;
    top: 100px;
    left: 0;
    bottom: 0;
    transition: all 0.5s ease-out;
    width: 100%;
    height: 0;
    // padding: 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    transition: $t-normal;
    gap: 30px;
    overflow: hidden;
    opacity: 0;
    background-image: url('./../../assets/img/wave-up-mobile.png');
    z-index: 100;
    background-position: bottom;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 100% 134px;
  }

  .nav-links-mobile-on {
    top: 0;
    position: fixed;
    opacity: 1;
    height: 100vh;
  }
}
