* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  position: relative;
}

body::-webkit-scrollbar {
  width: 0.6rem;
}

body::-webkit-scrollbar-track {
  background-color: rgba(150, 150, 149, 0.5);
}

body::-webkit-scrollbar-thumb {
  background-color: $c-yellow;
  border-radius: 10px;
  box-shadow: 0px 4px 20px #57627333;
}

body {
  font-family: $ff-main;
  font-size: $fs-main;
  font-weight: $fw-regular;
  background-color: rgb(236, 235, 235);
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: black;
}

img {
  display: block;
  width: 100%;
  height: 100%;
}

svg {
  width: 100%;
  height: 100%;
  overflow: visible;
}

.container {
  height: 100%; // допомагає, коли колір бекграунду не покриває весь контент внизу
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  background-color: $c-bg;
  position: relative;
}

.wrapper {
  padding-left: 30px;
  padding-right: 30px;

  @media screen and (max-width: 500px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.image {
  position: relative;
  width: 400px;
  height: 400px;
}

.icon-no {
  width: 15px;
  height: 15px;
}




