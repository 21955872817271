@import './../../../../styles/variables';

.for {
  padding-top: 80px;
  padding-bottom: 140px;
  background-color: $c-gray-bg;
  display: flex;
  flex-direction: column;
  align-items: center;

  .titleContainer {
    width: 350px;
    position: relative;
    margin-bottom: 50px;

    .iconContainer {
      position: absolute;
      right: -15px;
      width: 23px;
      height: 40px;

              @media screen and (max-width: 500px) {
          top: -5px;
          right: 32px;
        }
    }
  }

  .infos {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 140px;

    @media screen and (max-width: $desktopBig) {
      gap: 70px;
    }

    @media screen and (max-width: $desktop) {
      gap: 30px;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: start;
      gap: 15px;
    }

    .infoContainer {
      display: flex;
      align-items: center;
      gap: 15px;

      .number {
        width: 64px;
        height: 64px;
        background-color: $c-yellow;
        border-radius: 50%;
        color: $c-black;
        font-size: $fs-title;
        font-weight: $fw-bold;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        @media screen and (max-width: $desktop) {
          width: 50px;
          height: 50px;
          font-size: 22px;
        }
      }

      .text {
        font-weight: $fw-light;
        line-height: $line-height;

        @media screen and (max-width: $tablet) {
          font-size: 14px;
        }

        @media screen and (max-width: 600px) {
          font-size: 16px;
        }
      }
    }
  }
}
