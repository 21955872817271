@import './../../../styles/variables';

.card {
  position: relative;
  width: 100%;
  height: 260px;
  background-color: $c-bg;
  border-radius: 20px;
  padding: 55px 55px;
  box-shadow: 0px 4px 20px #57627333;
  overflow: hidden;

  @media screen and (max-width: 500px) {
    max-width: 320px;
    height: 420px;
  }

  @media screen and (min-width: 850px) and (max-width: 950px) {
    max-width: 240px;
    height: 340px;
    padding: 30px 25px;
  }

  @media screen and (min-width: 950px) and (max-width: 1000px) {
    max-width: 280px;
    height: 380px;
    padding: 30px 25px;
  }

  @media screen and (min-width: 1000px) and (max-width: 1100px) {
    max-width: 290px;
    height: 390px;
    padding: 30px 25px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1400px) {
    max-width: 320px;
    height: 420px;
    padding: 40px 30px;
  }
}

.card img {
  border-radius: 20px;
}

.title {
  font-size: $fs-lesson-form;
  text-shadow: 2px 2px $c-yellow;
  margin-bottom: 15px;

  @media screen and (max-width: 700px) {
    font-size: $fs-title;
  }

  @media screen and (min-width: 850px) and (max-width: 950px) {
    font-size: $fs-teachers;
  }
  @media screen and (min-width: 950px) and (max-width: 1050px) {
    font-size: $fs-course-title;
  }

  @media screen and (min-width: 1050px) and (max-width: 1440px) {
    font-size: $fs-title;
  }
}

.priceContainer {
  display: flex;
}

.price,
.time {
  font-size: $fs-teachers;
  font-weight: $fw-medium;
}

.currency,
.minutes {
  font-size: $fs-little-text;
}
