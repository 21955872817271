@import './../../styles/variables';

.mySwiper {
  // overflow: visible;
  height: 640px;
  // border: 1px solid red;
}

.mySwiper .swiper-slide {
  width: 100%;
  max-width: 330px;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  background-color: $c-bg;
  width: 48px;
  height: 48px;
  border: 2px solid $c-black;
  border-radius: 50%;
  transition: $t-normal;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: $c-yellow;
}

.swiper-button-prev {
  left: 46%;
  top: 94%;
}

.mySwiper .swiper-button-prev {
  @media screen and (max-width: 500px) {
    left: 34%;
    top: 94%;
  }

  @media screen and (min-width: 500px) and (max-width: 600px) {
    left: 38%;
    top: 94%;
  }

  @media screen and (min-width: 600px) and (max-width: 750px) {
    left: 41%;
    top: 94%;
  }

  @media screen and (min-width: 750px) and (max-width: 900px) {
    left: 42%;
    top: 94%;
  }

  @media screen and (min-width: 900px) and (max-width: 1200px) {
    left: 44%;
    top: 94%;
  }
  @media screen and (min-width: 1200px) and (max-width: 1400px) {
    left: 45%;
    top: 94%;
  }
  }

.swiper-button-next {
  right: 46%;
  top: 94%;
}

.mySwiper .swiper-button-next {
    @media screen and (max-width: 500px) {
    right: 34%;
    top: 94%;
  }

  @media screen and (min-width: 500px) and (max-width: 600px) {
    right: 38%;
    top: 94%;
  }

  @media screen and (min-width: 600px) and (max-width: 750px) {
    right: 41%;
    top: 94%;
  }

  @media screen and (min-width: 750px) and (max-width: 900px) {
    right: 42%;
    top: 94%;
  }

  @media screen and (min-width: 900px) and (max-width: 1200px) {
    right: 44%;
    top: 94%;
  }
  @media screen and (min-width: 1200px) and (max-width: 1400px) {
    right: 45%;
    top: 94%;
  }
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: '';
}

.swiper-button-prev::before {
  content: url('./../../assets/svg/arrow-left.svg');
  width: 27px;
  height: 15px;
}

.swiper-button-next::before {
  content: url('./../../assets/svg/arrow-right.svg');
  width: 27px;
  height: 15px;
}
