@import './../../styles/variables';

.wrapper {
  width: 100%;
  height: 100%;
  height: 790px;
  position: relative;
  background-color: $c-yellow;

  @media screen and (max-width: 499px) {
    height: 1610px;
  }

  @media screen and (min-width: 500px) and (max-width: 629px) {
    height: 1550px;
  }

  @media screen and (min-width: 630px) and (max-width: 849px) {
    height: 1100px;
  }

  @media screen and (min-width: 850px) and (max-width: 1099px) {
    height: 900px;
  }
}

.heading {
  padding-top: 62px;
}

.comment {
  position: absolute;
  width: 292px;
}

.comment:hover {
  z-index: 20;
}

.commentOne {
  left: 103px;
  top: 174px;

  @media screen and (max-width: 449px) {
    left: 1%;
    top: 138px;
  }

    @media screen and (min-width: 450px) and (max-width: 549px) {
    left: 2%;
    top: 150px;
  }

  @media screen and (min-width: 550px) and (max-width: 629px) {
    left: 10%;
    top: 150px;
  }

  @media screen and (min-width: 630px) and (max-width: 649px) {
    left: 10px;
    top: 150px;
  }

  @media screen and (min-width: 650px) and (max-width: 849px) {
    left: 50px;
    top: 150px;
  }

  @media screen and (min-width: 850px) and (max-width: 1300px) {
    left: 20px;
  }
}

.commentTwo {
  left: 329px;
  bottom: 244px;

  @media screen and (max-width: 449px) {
    left: 73px;
    bottom: 1090px;
  }

    @media screen and (min-width: 450px) and (max-width: 514px) {
    left: 33%;
    bottom: 520px;
  }

  @media screen and (min-width: 515px) and (max-width: 629px) {
    left: 42%;
    bottom: 520px;
  }

  @media screen and (min-width: 630px) and (max-width: 649px) {
    left: 300px;
    bottom: 500px;
  }

  @media screen and (min-width: 650px) and (max-width: 739px) {
    left: 350px;
    bottom: 520px;
  }

  @media screen and (min-width: 740px) and (max-width: 849px) {
    left: 430px;
    bottom: 520px;
  }

  @media screen and (min-width: 850px) and (max-width: 1099px) {
    left: 40px;
    bottom: 300px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1300px) {
    left: 200px;
  }
}

.commentThree {
  left: 90px;
  bottom: 165px;

  @media screen and (max-width: 449px) {
    bottom: 1022px;
    left: 70px;
  }

  @media screen and (min-width: 450px) and (max-width: 629px) {
    left: 15%;
    bottom: 150px;
  }

  @media screen and (min-width: 630px) and (max-width: 849px) {
    left: 100px;
    bottom: 100px;
  }

  @media screen and (min-width: 850px) and (max-width: 1300px) {
    left: 20px;
  }
}

.commentFour {
  top: 190px;
  left: 475px;

  @media screen and (max-width: 449px) {
    top: 600px;
    left: 14px;
  }

      @media screen and (min-width: 450px) and (max-width: 469px) {
    left: 30%;
    top: 340px;
  }

    @media screen and (min-width: 470px) and (max-width: 529px) {
    left: 35%;
    top: 340px;
  }

  @media screen and (min-width: 530px) and (max-width: 629px) {
    left: 42%;
    top: 340px;
  }

  @media screen and (min-width: 630px) and (max-width: 709px) {
    left: 320px;
  }

  @media screen and (min-width: 710px) and (max-width: 849px) {
    left: 400px;
  }

  @media screen and (min-width: 850px) and (max-width: 999px) {
    left: 320px;
  }

  @media screen and (min-width: 1000px) and (max-width: 1300px) {
    left: 380px;
  }
}

.commentFive {
  right: 488px;
  bottom: 336px;

  @media screen and (max-width: 449px) {
    right: 40px;
    bottom: 320px;
  }

    @media screen and (min-width: 450px) and (max-width: 499px) {
    left: 12%;
    bottom: 690px;
  }

  @media screen and (min-width: 500px) and (max-width: 629px) {
    left: 12%;
    bottom: 660px;
  }

  @media screen and (min-width: 630px) and (max-width: 649px) {
    left: 20px;
    bottom: 600px;
  }

  @media screen and (min-width: 650px) and (max-width: 849px) {
    left: 50px;
    bottom: 600px;
  }

  @media screen and (min-width: 850px) and (max-width: 899px) {
    left: 300px;
    bottom: 370px;
  }

  @media screen and (min-width: 900px) and (max-width: 1099px) {
    left: 370px;
    bottom: 370px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1230px) {
    right: 320px;
    bottom: 300px;
  }

  @media screen and (min-width: 1231px) and (max-width: 1400px) {
    right: 440px;
    bottom: 300px;
  }
}

.commentSix {
  bottom: 113px;
  right: 509px;

  @media screen and (max-width: 449px) {
    bottom: 107px;
    right: 0;
  }

  @media screen and (min-width: 450px) and (max-width: 629px) {
    bottom: 360px;
    left: 10%;
  }

  @media screen and (min-width: 630px) and (max-width: 649px) {
    bottom: 200px;
    right: 290px;
  }

  @media screen and (min-width: 650px) and (max-width: 769px) {
    bottom: 200px;
    right: 350px;
  }

  @media screen and (min-width: 770px) and (max-width: 849px) {
    right: 450px;
    bottom: 250px;
  }

  @media screen and (min-width: 850px) and (max-width: 899px) {
    right: 300px;
    bottom: 50px;
  }

  @media screen and (min-width: 900px) and (max-width: 989px) {
    right: 350px;
    bottom: 100px;
  }

  @media screen and (min-width: 990px) and (max-width: 1099px) {
    right: 410px;
    bottom: 100px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1230px) {
    right: 410px;
    bottom: 40px;
  }

  @media screen and (min-width: 1231px) and (max-width: 1400px) {
    bottom: 80px;
    right: 509px;
  }
}

.commentSeven {
  top: 126px;
  right: 280px;

  @media screen and (max-width: 449px) {
    top: 715px;
    right: 0;
  }

  @media screen and (min-width: 450px) and (max-width: 629px) {
    left: 14%;
    top: 600px;
  }

  @media screen and (min-width: 630px) and (max-width: 709px) {
    left: 320px;
    top: 600px;
  }

  @media screen and (min-width: 710px) and (max-width: 849px) {
    left: 400px;
    top: 600px;
  }

  @media screen and (min-width: 850px) and (max-width: 899px) {
    right: 10px;
    top: 100px;
  }

  @media screen and (min-width: 900px) and (max-width: 1099px) {
    right: 40px;
    top: 120px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1230px) {
    right: 130px;
    top: 100px;
  }

  @media screen and (min-width: 1231px) and (max-width: 1420px) {
    top: 126px;
    right: 230px;
  }
}

.commentEight {
  right: 124px;
  top: 288px;

  @media screen and (max-width: 449px) {
    top: 1047px;
    right: 1px;
  }

    @media screen and (min-width: 450px) and (max-width: 469px) {
    left: 30%;
    top: 490px;
  }

  @media screen and (min-width: 470px) and (max-width: 629px) {
    left: 35%;
    top: 490px;
  }

  @media screen and (min-width: 630px) and (max-width: 649px) {
    left: 20px;
    top: 530px;
  }

  @media screen and (min-width: 650px) and (max-width: 749px) {
    left: 60px;
    top: 530px;
  }

  @media screen and (min-width: 750px) and (max-width: 849px) {
    left: 130px;
    top: 530px;
  }

  @media screen and (min-width: 850px) and (max-width: 1099px) {
    right: 20px;
    top: 340px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1230px) {
    right: 20px;
  }

  @media screen and (min-width: 1231px) and (max-width: 1420px) {
    right: 60px;
    top: 288px;
  }
}

.commentNine {
  bottom: 158px;
  right: 186px;

  @media screen and (max-width: 449px) {
    bottom: 536px;
    right: 32px;
  }

  @media screen and (min-width: 450px) and (max-width: 629px) {
    right: 40px;
    bottom: 250px;
  }

  @media screen and (min-width: 630px) and (max-width: 769px) {
    right: 20px;
    bottom: 150px;
  }

  @media screen and (min-width: 770px) and (max-width: 849px) {
    right: 100px;
    bottom: 150px;
  }

  @media screen and (min-width: 850px) and (max-width: 1099px) {
    right: 50px;
    bottom: 200px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1230px) {
    right: 50px;
    bottom: 120px;
  }

  @media screen and (min-width: 1231px) and (max-width: 1420px) {
    bottom: 158px;
    right: 120px;
  }
}

.icon {
  position: absolute;
}

.smileyOne {
  width: 41px;
  bottom: 298px;
  left: 267px;

  @media screen and (max-width: 400px) {
    bottom: 1125px;
    left: 24px;
  }

  @media screen and (min-width: 400px) and (max-width: 629px) {
    left: 80%;
    bottom: 700px;
  }

  @media screen and (min-width: 630px) and (max-width: 709px) {
    left: 430px;
    bottom: 630px;
  }

  @media screen and (min-width: 710px) and (max-width: 849px) {
    left: 650px;
    bottom: 600px;
  }

  @media screen and (min-width: 850px) and (max-width: 1300px) {
    left: 130px;
    bottom: 200px;
  }
}

.smileyTwo {
  width: 45px;
  bottom: 130px;
  left: 565px;

  @media screen and (max-width: 449px) {
    bottom: 900px;
    left: 315px;
  }

    @media screen and (min-width: 450px) and (max-width: 499px) {
    left: 77%;
    bottom: 400px;
  }

  @media screen and (min-width: 500px) and (max-width: 629px) {
    left: 70%;
    bottom: 400px;
  }

  @media screen and (min-width: 630px) and (max-width: 739px) {
    left: 360px;
    bottom: 430px;
  }

  @media screen and (min-width: 740px) and (max-width: 849px) {
    left: 360px;
    bottom: 500px;
  }

  @media screen and (min-width: 850px) and (max-width: 899px) {
    left: 360px;
    bottom: 200px;
  }

  @media screen and (min-width: 900px) and (max-width: 1024px) {
    left: 360px;
    bottom: 240px;
  }

  @media screen and (min-width: 1025px) and (max-width: 1099px) {
    left: 450px;
    bottom: 240px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1110px) {
    bottom: 100px;
    left: 330px;
  }

  @media screen and (min-width: 1111px) and (max-width: 1280px) {
    bottom: 100px;
    left: 350px;
  }

  @media screen and (min-width: 1281px) and (max-width: 1420px) {
    bottom: 130px;
    left: 420px;
  }
}

.smileyThree {
  width: 45px;
  top: 220px;
  right: 590px;

  @media screen and (max-width: 450px) {
    right: 31px;
    top: 154px;
  }

  @media screen and (min-width: 450px) and (max-width: 629px) {
    right: 25%;
    top: 150px;
  }

  @media screen and (min-width: 630px) and (max-width: 659px) {
    right: 400px;
    top: 50px;
  }

  @media screen and (min-width: 660px) and (max-width: 849px) {
    right: 600px;
    top: 50px;
  }

  @media screen and (min-width: 850px) and (max-width: 1099px) {
    right: 600px;
    top: 60px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1420px) {
    right: 800px;
    top: 60px;
  }
}

.heart {
  width: 52px;
  bottom: 300px;
  right: 424px;

  @media screen and (max-width: 449px) {
    display: none;
  }

    @media screen and (min-width: 450px) and (max-width: 499px) {
    right: 80%;
    bottom: 220px;
  }

  @media screen and (min-width: 500px) and (max-width: 629px) {
    right: 70%;
    bottom: 220px;
  }

  @media screen and (min-width: 630px) and (max-width: 769px) {
    right: 330px;
    bottom: 140px;
  }

  @media screen and (min-width: 770px) and (max-width: 849px) {
    right: 470px;
    bottom: 150px;
  }

  @media screen and (min-width: 850px) and (max-width: 899px) {
    right: 150px;
    bottom: 60px;
  }

  @media screen and (min-width: 900px) and (max-width: 969px) {
    right: 240px;
    bottom: 80px;
  }

  @media screen and (min-width: 970px) and (max-width: 1079px) {
    right: 240px;
    bottom: 330px;
  }

  @media screen and (min-width: 1080px) and (max-width: 1099px) {
    right: 350px;
    bottom: 370px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1230px) {
    right: 240px;
    bottom: 250px;
  }

  @media screen and (min-width: 1231px) and (max-width: 1400px) {
    right: 360px;
  }
}

.ruler {
  width: 74px;
  left: 154px;
  bottom: 250px;

  @media screen and (max-width: 449px) {
    left: 0;
    bottom: 980px;
  }

  @media screen and (min-width: 450px) and (max-width: 629px) {
    left: 12%;
    bottom: 960px;
  }

  @media screen and (min-width: 630px) and (max-width: 849px) {
    left: 500px;
    bottom: 870px;
  }

  @media screen and (min-width: 850px) and (max-width: 899px) {
    left: 270px;
    top: 140px;
  }

  @media screen and (min-width: 900px) and (max-width: 1099px) {
    left: 270px;
    top: 170px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1300px) {
    left: 40px;
  }
}
