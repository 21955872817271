@import '../../styles/index.scss';

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  background-image: url('./../../assets/img/squares.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 450px;
  margin-bottom: 70px;

  @media screen and (max-width: 566px) {
    padding-top: 58px;
    margin-top: 46px;
    background-position: top;
    background-size: 490px 490px;
    margin-bottom: 0;
    // overflow: hidden;
  }

  @media screen and (max-width: 1100px) {
    align-items: center;
  }
}

.redDot {
  position: absolute;
  background-color: $c-red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: -23px;
  left: 52%;

  @media screen and (max-width: 400px) {
    top: 49px;
    left: 90%;
  }
}

.blueDot {
  position: absolute;
  right: 30px;
  top: 25%;
  background-color: $c-blue;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  @media screen and (max-width: 375px) {
    top: 65%;
    left: 78%;
  }

  @media screen and (min-width: 375px) and (max-width: 500px) {
    top: 70%;
    left: 78%;
  }

  @media screen and (min-width: 550px) and (max-width: 590px) {
    top: 15%;
    left: 78%;
  }

  @media screen and (min-width: 645px) and (max-width: 715px) {
    top: 15%;
    left: 78%;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  max-width: 590px;
  height: 580px;
  background-color: transparent;

  @media screen and (max-width: 500px) {
    // justify-content: start;
    padding-left: 13px;
    padding-right: 13px;
    height: 100%;
    padding-bottom: 34px;
  }
}

.title {
  margin-top: 0;
  width: 100%;
  font-size: $fs-main-title;
  font-weight: $fw-bold;
  line-height: 54px;
  margin-bottom: 25px;

  @media screen and (max-width: 500px) {
    font-size: $fs-title;
    margin-bottom: 14px;
    line-height: 40px;
  }

  @media screen and (min-width: 875px) and (max-width: 995px) {
    font-size: $fs-smaller-title;
    margin-bottom: 14px;
    line-height: 40px;
  }
}

.text {
  width: 100%;
  line-height: 26px;
  margin-bottom: 45px;

  @media screen and (max-width: 500px) {
    font-size: $fs-little-text;
  }
}

.image {
  position: relative;
  width: 100%;
  max-width: 564px;
  height: 580px;

  @media screen and (max-width: 1100px) {
    max-width: 400px;
    height: 416px;
  }

  @media screen and (max-width: 875px) {
    display: none;
  }
}
