@import '../../styles/index.scss';

.courseForm {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
}


.courseClose {
  display: none;
}

.btnClose {
  position: fixed;
  top: 5%;
  right: 10%;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: $t-normal;

  @media screen and (min-width: 801px) and (max-width: 899px) {
    width: 45px;
    height: 45px;
    
  }

  @media screen and (min-width: 700px) and (max-width: 800px) {
    width: 45px;
    height: 45px;
    right: 3%;
    top: 6%;
  }

  @media screen and (min-width: 540px) and (max-width: 699px) {
    width: 45px;
    height: 45px;
    right: 7%;
    top: 4%;
  }

  @media screen and (min-width: 374px) and (max-width: 539px) {
    width: 45px;
    height: 45px;
    right: 5%;
    top: 3%;
  }
}

.btnClose:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.line {
  position: relative;
  background-color: rgb(174, 174, 174);
  width: 45px;
  height: 4px;
  border-radius: 5px;
  transform: rotate(45deg);

  @media screen and (min-width: 300px) and (max-width: 899px) {
    width: 35px;
  }
}

.line:before {
  position: absolute;
  content: '';
  top: 10px;
  width: 45px;
  height: 4px;
  background-color: rgb(174, 174, 174);
  border-radius: 5px;
  transform: translate(0px, -10px) rotate(90deg);

  @media screen and (min-width: 300px) and (max-width: 899px) {
    width: 35px;
  }
}