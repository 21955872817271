@import './../../styles/variables';

.photosSwiper {
  height: 100%;
  max-height: 820px;
}

.photosSwiper .swiper-button-next {
  right: 44%;

  @media screen and (max-width: 550px) {
    right: 30%;
  }

  @media screen and (min-width: 551px) and (max-width: 700px) {
    top: 90%;
    right: 33%;
  }

  @media screen and (min-width: 701px) and (max-width: 900px) {
    top: 90%;
    right: 37%;
  }

  @media screen and (min-width: 901px) and (max-width: 1200px) {
    top: 90%;
    right: 40%;
  }
}

.photosSwiper .swiper-button-prev {
  left: 44%;

  @media screen and (max-width: 600px) {
    left: 30%;
  }

  @media screen and (min-width: 551px) and (max-width: 700px) {
    top: 90%;
    left: 33%;
  }

  @media screen and (min-width: 701px) and (max-width: 900px) {
    top: 90%;
    left: 37%;
  }

  @media screen and (min-width: 901px) and (max-width: 1200px) {
    top: 90%;
    left: 40%;
  }
}
