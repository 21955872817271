@import './../../styles/variables';

.wrapper {
  background-color: $c-gray-bg;
  padding-top: 77px;
  padding-bottom: 78px;

  @media screen and (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.cardsContainer {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 30px;
  width: 100%;
  justify-content: center;

  @media screen and (max-width: 499px) {
    flex-direction: column;
    align-items: center;
  }
}

.extraCardsContainer {
  opacity: 0;
  height: 0;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;

  transition: $t-normal;

  @media screen and (max-width: 499px) {
    flex-direction: column;
    align-items: center;
  }
}

.open {
  opacity: 1;
  height: 400px;
  overflow: visible;
  transition: $t-normal;

  @media screen and (max-width: 499px) {
    height: 1400px;
  }

  @media screen and (min-width: 500px) and (max-width: 700px) {
    height: 430px;
  }

  @media screen and (min-width: 700px) and (max-width: 800px) {
    height: 590px;
  }

  @media screen and (min-width: 800px) and (max-width: 1000px) {
    height: 680px;
  }

  @media screen and (min-width: 950px) and (max-width: 1150px) {
    height: 230px;
  }

  @media screen and (min-width: 1150px) and (max-width: 1250px) {
    height: 260px;
  }

  @media screen and (min-width: 1250px) and (max-width: 1450px) {
    height: 300px;
  }
}

.textContainer {
  text-align: center;
  line-height: $line-height;
  margin-bottom: 50px;
}

.description {
  margin-top: 10px;
}

.linkSize {
  width: fit-content;
}

.card {
  line-height: $line-height;
  position: relative;
  background-color: $c-bg;
  width: 330px;
  height: 330px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 20px;
  box-shadow: 0px 4px 20px #57627333;
  transition: $t-normal;

  @media screen and (max-width: 375px) {
    width: 320px;
  }

  @media screen and (min-width: 500px) and (max-width: 700px) {
    width: 205px;
    height: 205px;
    padding: 20px;
    line-height: $line-height-small;
  }

  @media screen and (min-width: 700px) and (max-width: 800px) {
    width: 280px;
    height: 280px;
    padding: 40px;
    line-height: $line-height-small;
  }

  @media screen and (min-width: 950px) and (max-width: 1100px) {
    width: 205px;
    height: 205px;
    padding: 20px;
    line-height: $line-height-small;
  }

  @media screen and (min-width: 1100px) and (max-width: 1250px) {
    width: 240px;
    height: 240px;
    padding: 30px;
    line-height: $line-height-small;
  }

  @media screen and (min-width: 1250px) and (max-width: 1450px) {
    width: 280px;
    height: 280px;
    padding: 30px;
    line-height: $line-height;
  }
}

.card:hover {
  transform: scale(1.1, 1.1);
}

.iconContainer {
  position: absolute;
  left: 33px;
  top: 33px;
  width: 74px;
  height: 48px;

  @media screen and (min-width: 500px) and (max-width: 700px) {
    width: 54px;
    height: 28px;
    left: 15px;
    top: 15px;
  }

  @media screen and (min-width: 700px) and (max-width: 800px) {
    width: 64px;
    height: 38px;
    left: 33px;
    top: 33px;
  }

  @media screen and (min-width: 950px) and (max-width: 1150px) {
    width: 54px;
    height: 28px;
    left: 15px;
    top: 15px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1450px) {
    width: 64px;
    height: 38px;
    left: 20px;
    top: 20px;
  }
}

.title {
  font-weight: $fw-medium;
  font-size: $fs-teachers;
  margin-bottom: 23px;

  @media screen and (min-width: 500px) and (max-width: 700px) {
    font-size: $fs-main;
    margin-bottom: 7px;
  }

  @media screen and (min-width: 700px) and (max-width: 800px) {
    font-size: $fs-navigation;
    margin-bottom: 15px;
  }

  @media screen and (min-width: 950px) and (max-width: 1150px) {
    font-size: $fs-main;
    margin-bottom: 7px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1250px) {
    font-size: $fs-navigation;
    margin-bottom: 15px;
  }

  @media screen and (min-width: 1250px) and (max-width: 1450px) {
    font-size: $fs-navigation;
    margin-bottom: 15px;
  }
}

.text {
  font-size: $fs-little-text;
  margin-bottom: 26px;

  @media screen and (min-width: 500px) and (max-width: 700px) {
    font-size: $fs-very-little;
    margin-bottom: 7px;
  }

  @media screen and (min-width: 700px) and (max-width: 800px) {
    font-size: $fs-little-text;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 950px) and (max-width: 1150px) {
    font-size: $fs-very-little;
    margin-bottom: 7px;
  }

  @media screen and (min-width: 1100px) and (max-width: 1250px) {
    font-size: $fs-little-text;
    margin-bottom: 15px;
  }

  @media screen and (min-width: 1250px) and (max-width: 1450px) {
    font-size: $fs-little-text;
    margin-bottom: 15px;
  }
}

.linkContainer {
  position: relative;
  width: fit-content;
  height: fit-content;
  transition: $t-normal;

  @media screen and (min-width: 1250px) and (max-width: 1450px) {
    margin-bottom: 15px;
  }
}

.link {
  position: relative;
  z-index: 1;
  color: $c-blue;
  font-family: $ff-secondary;
  font-size: $fs-navigation;
}

.linkContainer:hover {
  transform: scale(1.1, 1.1);
}

.shadow {
  position: absolute;
  background-color: $c-yellow;
  width: 66px;
  height: 8px;
  top: 8px;
}

.button {
  z-index: 1;
  display: block;
  margin: 0 auto;
  font-family: $ff-secondary;
  font-size: $fs-navigation;
  width: 160px;
  height: 61px;
  background-color: $c-yellow;
  border: 1px solid $c-black;
  border-radius: 40px;
  cursor: pointer;
  margin-top: 28px;
}

.button:hover {
  outline: 1px solid $c-black;
}

.button:active {
  background-color: $c-btn-active;
}
